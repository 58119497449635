import moment from 'moment';

export const sideMenuWidth = 231;

export const languages = [
  { title: 'EN', key: 'en' },
  { title: 'TH', key: 'th' }
];

export const statusOptions = [
  { title: 'Show', value: 'Show' },
  { title: 'Hide', value: 'Hide' }
];

export const resourceTypeOptions = [
  { title: 'Image', value: 'Image' },
  { title: 'Video', value: 'Video' }
];

export const mainConceptDescriptionPositionOptions = [
  { title: 'Left', value: 'Left' },
  { title: 'Right', value: 'Right' }
];

export const conceptStartOptions = [
  { title: 'Left', value: 'Left' },
  { title: 'Right', value: 'Right' }
];

export const overlayPositionOptions = [
  { title: 'Left', value: 'Left' },
  { title: 'Mid', value: 'Mid' },
  { title: 'Right', value: 'Right' }
];

export const currentDate = moment(new Date()).format('YYYY-MM-DD');

export const aboutUsBrandSections = [
  { name: 'Detail', value: '/about-us/brand/edit' },
  { name: 'Biography', value: '/about-us/brand/biography' },
  { name: 'Highlight', value: '/about-us/brand/highlight/edit' }
];

export const projectSectionNames = {
  default: 'Default',
  banner: 'Banner',
  register: 'Register',
  'main-concept': 'Main Concept',
  concept: 'Concept',
  'project-plan': 'Project Plan',
  // 'floor-plan': 'Floor Plan',
  // 'room-type': 'Room Type',
  location: 'Location',
  gallery: 'Gallery',
  panorama: 'Panorama',
  construction: 'Construction',
  vdo: 'Vdo',
  recommended: 'Recommended'
};

export const projectSectionOrders = {
  default: 1,
  banner: 2,
  register: 3,
  'main-concept': 4,
  concept: 5,
  // 'floor-plan': 7,
  'project-plan': 7,
  // 'room-type': 8,
  location: 9,
  gallery: 10,
  panorama: 11,
  construction: 12,
  vdo: 13,
  recommended: 14
};

export const allowPathsOptions = [
  { title: 'career', key: 'career' },
  { title: 'contact-us', key: 'contact-us' },
  { title: 'disclaimer', key: 'disclaimer' },
  { title: 'email-receiver', key: 'email-receiver' },
  { title: 'home', key: 'home' },
  { title: 'line-user', key: 'line-user' },
  { title: 'news', key: 'news' },
  { title: 'policy', key: 'policy' },
  { title: 'project', key: 'project' },
  { title: 'project-progress', key: 'project-progress' },
  { title: 'promotion', key: 'promotion' },
  { title: 'subscribe', key: 'subscribe' },
  { title: 'user', key: 'user' }
];

export const typeOptions = [
  { title: 'Project', value: 'Project' },
  { title: 'Facility', value: 'Facility' },
  { title: 'Detail', value: 'Detail' }
];

export const backgroundTypeOptions = [
  { title: 'Image', value: 'Image' },
  { title: 'Color', value: 'Color' }
];

export const projectPlanTypeOptions = [
  { title: 'Master Plan', value: 'master-plans' },
  { title: 'Floor Plan', value: 'floor-plans' },
  { title: 'Unit Type', value: 'unit-types' }
];

export const pageOptions = [
  { title: 'Career', value: 'career' },
  { title: 'Contact Us', value: 'contact-us' },
  { title: 'Project', value: 'project' },
  { title: 'Subscribe', value: 'subscribe' }
];
