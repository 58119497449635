import _ from 'lodash';

const initialState = {
  currentLanguage: 'en'
};

export default (state = initialState, action) => {
  const data = _.get(action, 'data');
  switch (action.type) {
    case 'ENTITIES/DATA_WITH_KEY/RECEIVED':
      return {
        ...state,
        [_.get(data, 'key')]: _.get(data, 'docData')
      };
    default:
      return {
        ...state
      };
  }
};
