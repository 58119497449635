import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Select } from 'antd';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import {
  backgroundTypeOptions,
  projectSectionNames,
  projectPlanTypeOptions
} from '../../../utils/variables';
import {
  appendSectionSwitchInputs,
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;
const { Option } = Select;

const rootPath = 'project';
const outerDataKey = 'project-plan';

function Index(props) {
  const action = 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const path = `project/edit/${_id}/project-plan`;
  const [planType, setPlanType] = useState(
    localStorage.getItem('planType') || 'master-plans'
  );
  const [title, setTitle] = useState();
  const [backgroundType, setBackgroundType] = useState('Image');
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSections, setShowSections] = useState();
  const tmpInputFields = [];
  const [forceUpdate, setForceUpdate] = useState();
  let dataKey = `project-plan.${planType}`;
  appendSectionSwitchInputs(
    'showSections',
    tmpInputFields,
    showSections,
    setShowSections,
    setForceUpdate
  );
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: `/project/edit/${_id}` }
  ];
  appendSectionSelectOptions(
    sectionSelectOptions,
    showSections,
    `/project/edit/${_id}`
  );
  sortArrayWithKey(sectionSelectOptions, 'order');
  useEffect(() => {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      const outerData =
        outerDataKey !== ''
          ? _.get(result, `data.data.${outerDataKey}`, [])
          : '';
      setTitle(_.get(outerData, 'title'));
      setBackgroundType(_.get(outerData, 'backgroundType', 'Image'));
      setBackgroundImage(_.get(outerData, 'backgroundImage'));
      setBackgroundColor(_.get(outerData, 'backgroundColor'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      sortArrayWithKey(data, 'order');
      setShowSections(_.get(result, 'data.data.showSections'));
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
  }, [planType]);
  const columns = [
    { title: 'Title', dataIndex: 'title.en' },
    { title: 'Alt', dataIndex: 'alt.en' },
    { title: 'Status', dataIndex: 'status' },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/${planType}/edit/${_.get(record, '_id')}`} />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_id}
            data={data}
            setData={setData}
            childId={_.get(record, '_id')}
            dataKey={dataKey}
          />
        </div>
      )
    }
  ];
  const handlePlanTypeChange = e => {
    setPlanType(e);
    localStorage.setItem('planType', e);
  };
  let tableTitle;
  switch (planType) {
    case 'floor-plans':
      tableTitle = 'Floor Plan';
      break;
    case 'unit-types':
      tableTitle = 'Unit Type';
      break;
    default:
      tableTitle = 'Master Plan';
      break;
  }
  return (
    <Styled>
      <Select
        value={planType}
        onChange={e => handlePlanTypeChange(e)}
        style={{ margin: '20px 0 0 20px' }}
      >
        {_.map(projectPlanTypeOptions, option => {
          return (
            <Option key={_.get(option, 'title')} value={_.get(option, 'value')}>
              {_.get(option, 'title')}
            </Option>
          );
        })}
      </Select>
      <CustomTable
        autoHeight={true}
        title={`${tableTitle} List`}
        sectionSelectInfo={{
          label: 'Sections',
          defaultValue: `/${path}`,
          sections: sectionSelectOptions
        }}
        searchKeys={['_id', 'title.en', 'alt.en', 'status']}
        rootId={_id}
        childKey={dataKey}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/${planType}/new` }
        ]}
      />
      <CustomForm
        autoHeight={true}
        action={action}
        title={`${action} Project Plan Section`}
        inputFields={[
          // { key: 'sectionSelect', label: 'Sections', type: 'sectionSelect', defaultValue: `/${path}`, sections: sectionSelectOptions },
          {
            key: 'title',
            state: title,
            setState: setTitle,
            label: 'Title',
            multiLang: true,
            type: 'text'
          },
          {
            key: 'backgroundType',
            state: backgroundType,
            setState: setBackgroundType,
            label: 'Background Type',
            type: 'select',
            valuesForSelect: backgroundTypeOptions
          },
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x1080',
            previewWidth: '400px'
          },
          {
            key: 'backgroundColor',
            state: backgroundColor,
            setState: setBackgroundColor,
            label: 'Background Color',
            type: 'colorPicker'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: outerDataKey,
            updateOuter: true
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default Index;
