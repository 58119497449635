import styled from 'styled-components';

export default styled.div`
  .select-container {
    display: flex;
    align-items: center;
    margin: 20px 0 0 20px;
    .select-label {
      margin-right: 10px;
    }
    .ant-select {
      width: 150px;
    }
  }
`;
