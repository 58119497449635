import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import ReactDragListView from 'react-drag-listview';
import { Table, Spin, Input } from 'antd';
import Fuse from 'fuse.js';

import Styled from './styled';
import CustomButton from '../CustomButton';
import { SectionSelectInput, DateRangeInput } from '../CustomInput';
import {
  handleTableChange,
  onDragEnd,
  createFuseOption
} from '../../utils/global';
import { handleSearchChange } from '../../utils/actionHandle';

const { NewButton, DownloadExcelButton } = CustomButton;

let fuse;

export default withRouter(function CustomTable(props) {
  /* 
  props
    title(string),
    data(objectArray),
    setData(function),
    columns([{ title(string), dataIndex(string), sorter(function), render(function) }]),
    actionComponents([{ type(string), title(string), path(string) }]),
    isLoading(boolean),
  */

  const {
    title,
    data,
    setData,
    columns,
    actionComponents,
    isLoading,
    setIsLoading,
    path,
    history,
    sectionSelectInfo,
    searchKeys,
    fullData,
    rootId,
    childKey,
    autoHeight
  } = props;
  const [pageSize, setPageSize] = useState(
    _.toNumber(localStorage.getItem('pageSize')) || 10
  );
  const [initialRowIndexToDrag, setInitialRowIndexToDrag] = useState(0);
  const newBtnAction = _.find(
    actionComponents,
    a => _.get(a, 'type') === 'new'
  );
  const dateRangeAction = _.find(
    actionComponents,
    a => _.get(a, 'type') === 'dateRange'
  );
  const excelAction = _.find(
    actionComponents,
    a => _.get(a, 'type') === 'excel'
  );
  const excelData = _.get(excelAction, 'data');
  const excelDataArray = [];
  const excelMultiDataset = [];
  useEffect(() => {
    const fuseOptions = createFuseOption(searchKeys);
    fuse = new Fuse(fullData, fuseOptions);
  }, [fullData]);
  const prepareExcelData = () => {
    const valueKeys = _.get(excelAction, 'valueKeys');
    if (excelData && _.get(excelData, 'length') > 0) {
      _.map(excelData, d => {
        const rowData = [];
        _.map(valueKeys, vk => {
          rowData.push({ value: _.get(d, vk) });
        });
        excelDataArray.push(rowData);
      });
      excelMultiDataset.push({
        columns: _.get(excelAction, 'columns'),
        data: excelDataArray
      });
      return true;
    } else {
      return false;
    }
  };
  return (
    <Spin spinning={isLoading} size='large'>
      <Styled autoHeight={autoHeight}>
        {sectionSelectInfo && (
          <SectionSelectInput input={sectionSelectInfo} history={history} />
        )}
        <div className='page-title-container'>
          <div className='left-container'>
            <h2 className='page-title'>{title}</h2>
            <div className='sub-title-container'>
              <Input
                className='search'
                onChange={e => handleSearchChange(e, fuse, setData, fullData)}
                type='text'
                placeholder='Search'
              />
              {dateRangeAction && (
                <DateRangeInput input={_.get(dateRangeAction, 'inputOption')} />
              )}
              {excelAction && prepareExcelData() && (
                <DownloadExcelButton
                  fileName={_.get(excelAction, 'fileName')}
                  columns={_.get(excelAction, 'columns')}
                  data={_.get(excelAction, 'data')}
                  sheetName={_.get(excelAction, 'sheetName')}
                  excelMultiDataset={excelMultiDataset}
                ></DownloadExcelButton>
              )}
            </div>
          </div>
          <div className='right-container'>
            {newBtnAction && (
              <NewButton
                path={_.get(newBtnAction, 'path')}
                title={_.get(newBtnAction, 'title')}
              />
            )}
          </div>
        </div>
        <ReactDragListView
          onDragEnd={onDragEnd(
            data,
            setData,
            initialRowIndexToDrag,
            setIsLoading,
            `${process.env.REACT_APP_API_HOST}/${path}/update`,
            rootId,
            childKey
          )}
          handleSelector='tr'
        >
          <Table
            onChange={handleTableChange(
              setPageSize,
              setData,
              setInitialRowIndexToDrag
            )}
            style={{ overflowX: 'auto' }}
            rowKey='_id'
            columns={columns}
            dataSource={data}
            sectionSelectInfo={{ input: sectionSelectInfo }}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '25', '50', '100', '200', '500']
            }}
          />
        </ReactDragListView>
      </Styled>
    </Spin>
  );
});
