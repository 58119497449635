import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { sendGet } from '../../../utils/requestHandle';
import { statusOptions } from '../../../utils/variables';

const rootPath = 'contact-us';
const path = 'contact-us';
const dataKey = 'banner';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [forceUpdate, setForceUpdate] = useState();
  useEffect(() => {
    // if (action === 'Edit') {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      set_id(_.get(result, 'data.data._id'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      setTitle(_.get(data, 'title'));
      setBackgroundImage(_.get(data, 'backgroundImage'));
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
    // }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Banner`}
        inputFields={[
          {
            key: 'title',
            state: title,
            setState: setTitle,
            multiLang: true,
            label: 'Title',
            type: 'text'
          },
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x480',
            previewWidth: '400px'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}/banner/edit`,
            dataKey: dataKey
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
