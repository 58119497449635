import React from 'react';
import { Route } from 'react-router-dom';
import HomeBanner from '../pages/Home/Banner';
import HomeBannerForm from '../pages/Home/Banner/form';
import HomeSearchForm from '../pages/Home/Search/form';
import HomePromotionForm from '../pages/Home/Promotion/form';
import HomeAboutForm from '../pages/Home/About/form';
import HomeNewsForm from '../pages/Home/News/form';
import NewsArticle from '../pages/News/Article';
import NewsArticleForm from '../pages/News/Article/form';
import PromotionArticle from '../pages/Promotion/Article';
import PromotionArticleForm from '../pages/Promotion/Article/form';
import PolicyForm from '../pages/Policy/form';
import DisclaimerForm from '../pages/Disclaimer/form';
import CareerBannerForm from '../pages/Career/BannerSection/form';
import CareerPrivilege from '../pages/Career/PrivilegeSection';
import CareerPrivilegeForm from '../pages/Career/PrivilegeSection/form';
import CareerPosition from '../pages/Career/PositionSection';
import CareerPositionForm from '../pages/Career/PositionSection/form';
import CareerFormSubmit from '../pages/Career/FormSubmit';
import CareerFormSubmitForm from '../pages/Career/FormSubmit/form';
import ContactUsBannerForm from '../pages/ContactUs/BannerSection/form';
import ContactUsContact from '../pages/ContactUs/ContactSection';
import ContactUsContactForm from '../pages/ContactUs/ContactSection/form';
import ContactUsMapForm from '../pages/ContactUs/MapSection/form';
import ContactUsFormSubmit from '../pages/ContactUs/FormSubmit';
import ContactUsFormSubmitForm from '../pages/ContactUs/FormSubmit/form';
import Project from '../pages/Project';
import ProjectForm from '../pages/Project/form';
import ProjectBannerSection from '../pages/Project/BannerSection';
import ProjectBannerSectionForm from '../pages/Project/BannerSection/form';
import ProjectRegisterSectionForm from '../pages/Project/RegisterSection/form';
import ProjectMainConceptSection from '../pages/Project/MainConceptSection';
import ProjectMainConceptSectionForm from '../pages/Project/MainConceptSection/form';
import ProjectConceptSection from '../pages/Project/ConceptSection';
import ProjectConceptSectionForm from '../pages/Project/ConceptSection/form';
import ProjectDetailSection from '../pages/Project/DetailSection';
import ProjectDetailSectionForm from '../pages/Project/DetailSection/form';
import ProjectProjectPlanSection from '../pages/Project/ProjectPlanSection';
import ProjectProjectPlanSectionForm from '../pages/Project/ProjectPlanSection/form';
import ProjectFloorPlanSection from '../pages/Project/FloorPlanSection';
import ProjectFloorPlanSectionForm from '../pages/Project/FloorPlanSection/form';
import ProjectRoomTypeSection from '../pages/Project/RoomTypeSection';
import ProjectRoomTypeSectionForm from '../pages/Project/RoomTypeSection/form';
import ProjectLocationSection from '../pages/Project/LocationSection';
import ProjectLocationSectionForm from '../pages/Project/LocationSection/form';
import ProjectGallerySection from '../pages/Project/GallerySection';
import ProjectGallerySectionForm from '../pages/Project/GallerySection/form';
import ProjectVdoSection from '../pages/Project/VdoSection';
import ProjectVdoSectionForm from '../pages/Project/VdoSection/form';
import ProjectPanoramaSection from '../pages/Project/PanoramaSection';
import ProjectPanoramaSectionForm from '../pages/Project/PanoramaSection/form';
import ProjectConstructionSection from '../pages/Project/ConstructionSection';
import ProjectConstructionSectionForm from '../pages/Project/ConstructionSection/form';
import ProjectRecommendedSectionForm from '../pages/Project/RecommendedSection/form';
import ProjectProgressBannerForm from '../pages/ProjectProgress/BannerSection/form';
import ProjectProgressProgressForm from '../pages/ProjectProgress/ProgressSection/form';

import ProjectLocation from '../pages/Project/Location';
import ProjectLocationForm from '../pages/Project/Location/form';
import ProjectPrice from '../pages/Project/Price';
import ProjectPriceForm from '../pages/Project/Price/form';
import ProjectStatus from '../pages/Project/Status';
import ProjectStatusForm from '../pages/Project/Status/form';
import ProjectType from '../pages/Project/Type';
import ProjectTypeForm from '../pages/Project/Type/form';
import ProjectRoomType from '../pages/Project/RoomType';
import ProjectRoomTypeForm from '../pages/Project/RoomType/form';
import ProjectBudget from '../pages/Project/Budget';
import ProjectBudgetForm from '../pages/Project/Budget/form';
import ProjectPeriodToContact from '../pages/Project/PeriodToContact';
import ProjectPeriodToContactForm from '../pages/Project/PeriodToContact/form';
import ProjectFormSubmit from '../pages/Project/FormSubmit';
import ProjectFormSubmitForm from '../pages/Project/FormSubmit/form';

import User from '../pages/User';
import UserForm from '../pages/User/form';
import UserLogin from '../pages/User/Login';

import SubscribeFormSubmit from '../pages/Subscribe/FormSubmit';
import EmailReceiver from '../pages/EmailReceiver';
import EmailReceiverForm from '../pages/EmailReceiver/form';
import LineUser from '../pages/Line/User';
import LineUserForm from '../pages/Line/User/form';

const routes = [
  <Route key={'/'} path={'/'} exact={true} component={CareerBannerForm} />,
  <Route
    key={'/career/banner/edit'}
    path={'/career/banner/edit'}
    exact={true}
    component={CareerBannerForm}
  />,
  <Route
    key={'/career/privilege'}
    path={'/career/privilege'}
    exact={true}
    component={CareerPrivilege}
  />,
  <Route
    key={'/career/privilege/new'}
    path={'/career/privilege/new'}
    exact={true}
    component={CareerPrivilegeForm}
  />,
  <Route
    key={'/career/privilege/edit/:_id'}
    path={'/career/privilege/edit/:_id'}
    exact={true}
    component={CareerPrivilegeForm}
  />,
  <Route
    key={'/career/position'}
    path={'/career/position'}
    exact={true}
    component={CareerPosition}
  />,
  <Route
    key={'/career/position/new'}
    path={'/career/position/new'}
    exact={true}
    component={CareerPositionForm}
  />,
  <Route
    key={'/career/position/edit/:_id'}
    path={'/career/position/edit/:_id'}
    exact={true}
    component={CareerPositionForm}
  />,
  <Route
    key={'/career/form-submit'}
    path={'/career/form-submit'}
    exact={true}
    component={CareerFormSubmit}
  />,
  <Route
    key={'/career/form-submit/edit/:_id'}
    path={'/career/form-submit/edit/:_id'}
    exact={true}
    component={CareerFormSubmitForm}
  />,

  <Route
    key={'/contact-us/banner/edit'}
    path={'/contact-us/banner/edit'}
    exact={true}
    component={ContactUsBannerForm}
  />,
  <Route
    key={'/contact-us/contact'}
    path={'/contact-us/contact'}
    exact={true}
    component={ContactUsContact}
  />,
  <Route
    key={'/contact-us/contact/new'}
    path={'/contact-us/contact/new'}
    exact={true}
    component={ContactUsContactForm}
  />,
  <Route
    key={'/contact-us/contact/edit/:_id'}
    path={'/contact-us/contact/edit/:_id'}
    exact={true}
    component={ContactUsContactForm}
  />,
  <Route
    key={'/contact-us/map/edit'}
    path={'/contact-us/map/edit'}
    exact={true}
    component={ContactUsMapForm}
  />,
  <Route
    key={'/contact-us/form-submit'}
    path={'/contact-us/form-submit'}
    exact={true}
    component={ContactUsFormSubmit}
  />,
  <Route
    key={'/contact-us/form-submit/edit/:_id'}
    path={'/contact-us/form-submit/edit/:_id'}
    exact={true}
    component={ContactUsFormSubmitForm}
  />,

  <Route
    key={'/disclaimer/edit'}
    path={'/disclaimer/edit'}
    exact={true}
    component={DisclaimerForm}
  />,

  <Route
    key={'/home/banner'}
    path={'/home/banner'}
    exact={true}
    component={HomeBanner}
  />,
  <Route
    key={'/home/banner/new'}
    path={'/home/banner/new'}
    exact={true}
    component={HomeBannerForm}
  />,
  <Route
    key={'/home/banner/edit/:_id'}
    path={'/home/banner/edit/:_id'}
    exact={true}
    component={HomeBannerForm}
  />,

  <Route
    key={'/home/search/edit'}
    path={'/home/search/edit'}
    exact={true}
    component={HomeSearchForm}
  />,
  <Route
    key={'/home/promotion/edit'}
    path={'/home/promotion/edit'}
    exact={true}
    component={HomePromotionForm}
  />,
  <Route
    key={'/home/about/edit'}
    path={'/home/about/edit'}
    exact={true}
    component={HomeAboutForm}
  />,
  <Route
    key={'/home/news/edit'}
    path={'/home/news/edit'}
    exact={true}
    component={HomeNewsForm}
  />,

  <Route
    key={'/news/article'}
    path={'/news/article'}
    exact={true}
    component={NewsArticle}
  />,
  <Route
    key={'/news/article/new'}
    path={'/news/article/new'}
    exact={true}
    component={NewsArticleForm}
  />,
  <Route
    key={'/news/article/edit/:_id'}
    path={'/news/article/edit/:_id'}
    exact={true}
    component={NewsArticleForm}
  />,

  <Route
    key={'/policy/edit'}
    path={'/policy/edit'}
    exact={true}
    component={PolicyForm}
  />,

  <Route
    key={'/promotion/article'}
    path={'/promotion/article'}
    exact={true}
    component={PromotionArticle}
  />,
  <Route
    key={'/promotion/article/new'}
    path={'/promotion/article/new'}
    exact={true}
    component={PromotionArticleForm}
  />,
  <Route
    key={'/promotion/article/edit/:_id'}
    path={'/promotion/article/edit/:_id'}
    exact={true}
    component={PromotionArticleForm}
  />,

  <Route key={'/project'} path={'/project'} exact={true} component={Project} />,
  <Route
    key={'/project/new'}
    path={'/project/new'}
    exact={true}
    component={ProjectForm}
  />,
  <Route
    key={'/project/edit/:_id'}
    path={'/project/edit/:_id'}
    exact={true}
    component={ProjectForm}
  />,
  <Route
    key={'/project/edit/:_id/banner'}
    path={'/project/edit/:_id/banner'}
    exact={true}
    component={ProjectBannerSection}
  />,
  <Route
    key={'/project/edit/:_id/banner/new'}
    path={'/project/edit/:_id/banner/new'}
    exact={true}
    component={ProjectBannerSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/banner/edit/:child_id'}
    path={'/project/edit/:_id/banner/edit/:child_id'}
    exact={true}
    component={ProjectBannerSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/register'}
    path={'/project/edit/:_id/register'}
    exact={true}
    component={ProjectRegisterSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/main-concept'}
    path={'/project/edit/:_id/main-concept'}
    exact={true}
    component={ProjectMainConceptSection}
  />,
  <Route
    key={'/project/edit/:_id/main-concept/new'}
    path={'/project/edit/:_id/main-concept/new'}
    exact={true}
    component={ProjectMainConceptSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/main-concept/edit/:child_id'}
    path={'/project/edit/:_id/main-concept/edit/:child_id'}
    exact={true}
    component={ProjectMainConceptSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/concept'}
    path={'/project/edit/:_id/concept'}
    exact={true}
    component={ProjectConceptSection}
  />,
  <Route
    key={'/project/edit/:_id/concept/new'}
    path={'/project/edit/:_id/concept/new'}
    exact={true}
    component={ProjectConceptSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/concept/edit/:child_id'}
    path={'/project/edit/:_id/concept/edit/:child_id'}
    exact={true}
    component={ProjectConceptSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/detail'}
    path={'/project/edit/:_id/detail'}
    exact={true}
    component={ProjectDetailSection}
  />,
  <Route
    key={'/project/edit/:_id/detail/new'}
    path={'/project/edit/:_id/detail/new'}
    exact={true}
    component={ProjectDetailSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/detail/edit/:child_id'}
    path={'/project/edit/:_id/detail/edit/:child_id'}
    exact={true}
    component={ProjectDetailSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/project-plan'}
    path={'/project/edit/:_id/project-plan'}
    exact={true}
    component={ProjectProjectPlanSection}
  />,
  <Route
    key={'/project/edit/:_id/project-plan/:planType?/new'}
    path={'/project/edit/:_id/project-plan/:planType?/new'}
    exact={true}
    component={ProjectProjectPlanSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/project-plan/:planType?/edit/:child_id'}
    path={'/project/edit/:_id/project-plan/:planType?/edit/:child_id'}
    exact={true}
    component={ProjectProjectPlanSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/floor-plan'}
    path={'/project/edit/:_id/floor-plan'}
    exact={true}
    component={ProjectFloorPlanSection}
  />,
  <Route
    key={'/project/edit/:_id/floor-plan/new'}
    path={'/project/edit/:_id/floor-plan/new'}
    exact={true}
    component={ProjectFloorPlanSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/floor-plan/edit/:child_id'}
    path={'/project/edit/:_id/floor-plan/edit/:child_id'}
    exact={true}
    component={ProjectFloorPlanSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/room-type'}
    path={'/project/edit/:_id/room-type'}
    exact={true}
    component={ProjectRoomTypeSection}
  />,
  <Route
    key={'/project/edit/:_id/room-type/new'}
    path={'/project/edit/:_id/room-type/new'}
    exact={true}
    component={ProjectRoomTypeSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/room-type/edit/:child_id'}
    path={'/project/edit/:_id/room-type/edit/:child_id'}
    exact={true}
    component={ProjectRoomTypeSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/location'}
    path={'/project/edit/:_id/location'}
    exact={true}
    component={ProjectLocationSection}
  />,
  <Route
    key={'/project/edit/:_id/location/new'}
    path={'/project/edit/:_id/location/new'}
    exact={true}
    component={ProjectLocationSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/location/edit/:child_id'}
    path={'/project/edit/:_id/location/edit/:child_id'}
    exact={true}
    component={ProjectLocationSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/gallery'}
    path={'/project/edit/:_id/gallery'}
    exact={true}
    component={ProjectGallerySection}
  />,
  <Route
    key={'/project/edit/:_id/gallery/new'}
    path={'/project/edit/:_id/gallery/new'}
    exact={true}
    component={ProjectGallerySectionForm}
  />,
  <Route
    key={'/project/edit/:_id/gallery/edit/:child_id'}
    path={'/project/edit/:_id/gallery/edit/:child_id'}
    exact={true}
    component={ProjectGallerySectionForm}
  />,
  <Route
    key={'/project/edit/:_id/panorama'}
    path={'/project/edit/:_id/panorama'}
    exact={true}
    component={ProjectPanoramaSection}
  />,
  <Route
    key={'/project/edit/:_id/panorama/new'}
    path={'/project/edit/:_id/panorama/new'}
    exact={true}
    component={ProjectPanoramaSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/panorama/edit/:child_id'}
    path={'/project/edit/:_id/panorama/edit/:child_id'}
    exact={true}
    component={ProjectPanoramaSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/construction'}
    path={'/project/edit/:_id/construction'}
    exact={true}
    component={ProjectConstructionSection}
  />,
  <Route
    key={'/project/edit/:_id/construction/new'}
    path={'/project/edit/:_id/construction/new'}
    exact={true}
    component={ProjectConstructionSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/construction/edit/:child_id'}
    path={'/project/edit/:_id/construction/edit/:child_id'}
    exact={true}
    component={ProjectConstructionSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/vdo'}
    path={'/project/edit/:_id/vdo'}
    exact={true}
    component={ProjectVdoSection}
  />,
  <Route
    key={'/project/edit/:_id/vdo/new'}
    path={'/project/edit/:_id/vdo/new'}
    exact={true}
    component={ProjectVdoSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/vdo/edit/:child_id'}
    path={'/project/edit/:_id/vdo/edit/:child_id'}
    exact={true}
    component={ProjectVdoSectionForm}
  />,
  <Route
    key={'/project/edit/:_id/recommended'}
    path={'/project/edit/:_id/recommended'}
    exact={true}
    component={ProjectRecommendedSectionForm}
  />,

  <Route
    key={'/project/location'}
    path={'/project/location'}
    exact={true}
    component={ProjectLocation}
  />,
  <Route
    key={'/project/location/new'}
    path={'/project/location/new'}
    exact={true}
    component={ProjectLocationForm}
  />,
  <Route
    key={'/project/location/edit/:_id'}
    path={'/project/location/edit/:_id'}
    exact={true}
    component={ProjectLocationForm}
  />,

  <Route
    key={'/project/price'}
    path={'/project/price'}
    exact={true}
    component={ProjectPrice}
  />,
  <Route
    key={'/project/price/new'}
    path={'/project/price/new'}
    exact={true}
    component={ProjectPriceForm}
  />,
  <Route
    key={'/project/price/edit/:_id'}
    path={'/project/price/edit/:_id'}
    exact={true}
    component={ProjectPriceForm}
  />,

  <Route
    key={'/project/status'}
    path={'/project/status'}
    exact={true}
    component={ProjectStatus}
  />,
  <Route
    key={'/project/status/new'}
    path={'/project/status/new'}
    exact={true}
    component={ProjectStatusForm}
  />,
  <Route
    key={'/project/status/edit/:_id'}
    path={'/project/status/edit/:_id'}
    exact={true}
    component={ProjectStatusForm}
  />,

  <Route
    key={'/project/type'}
    path={'/project/type'}
    exact={true}
    component={ProjectType}
  />,
  <Route
    key={'/project/type/new'}
    path={'/project/type/new'}
    exact={true}
    component={ProjectTypeForm}
  />,
  <Route
    key={'/project/type/edit/:_id'}
    path={'/project/type/edit/:_id'}
    exact={true}
    component={ProjectTypeForm}
  />,

  <Route
    key={'/project/room-type'}
    path={'/project/room-type'}
    exact={true}
    component={ProjectRoomType}
  />,
  <Route
    key={'/project/room-type/new'}
    path={'/project/room-type/new'}
    exact={true}
    component={ProjectRoomTypeForm}
  />,
  <Route
    key={'/project/room-type/edit/:_id'}
    path={'/project/room-type/edit/:_id'}
    exact={true}
    component={ProjectRoomTypeForm}
  />,

  <Route
    key={'/project/budget'}
    path={'/project/budget'}
    exact={true}
    component={ProjectBudget}
  />,
  <Route
    key={'/project/budget/new'}
    path={'/project/budget/new'}
    exact={true}
    component={ProjectBudgetForm}
  />,
  <Route
    key={'/project/budget/edit/:_id'}
    path={'/project/budget/edit/:_id'}
    exact={true}
    component={ProjectBudgetForm}
  />,

  <Route
    key={'/project/period-to-contact'}
    path={'/project/period-to-contact'}
    exact={true}
    component={ProjectPeriodToContact}
  />,
  <Route
    key={'/project/period-to-contact/new'}
    path={'/project/period-to-contact/new'}
    exact={true}
    component={ProjectPeriodToContactForm}
  />,
  <Route
    key={'/project/period-to-contact/edit/:_id'}
    path={'/project/period-to-contact/edit/:_id'}
    exact={true}
    component={ProjectPeriodToContactForm}
  />,

  <Route
    key={'/project/form-submit'}
    path={'/project/form-submit'}
    exact={true}
    component={ProjectFormSubmit}
  />,
  <Route
    key={'/project/form-submit/edit/:_id'}
    path={'/project/form-submit/edit/:_id'}
    exact={true}
    component={ProjectFormSubmitForm}
  />,

  <Route
    key={'/project-progress/banner/edit'}
    path={'/project-progress/banner/edit'}
    exact={true}
    component={ProjectProgressBannerForm}
  />,
  <Route
    key={'/project-progress/progress/edit'}
    path={'/project-progress/progress/edit'}
    exact={true}
    component={ProjectProgressProgressForm}
  />,

  <Route key={'/user'} path={'/user'} exact={true} component={User} />,
  <Route
    key={'/user/new'}
    path={'/user/new'}
    exact={true}
    component={UserForm}
  />,
  <Route
    key={'/user/edit/:_id'}
    path={'/user/edit/:_id'}
    exact={true}
    component={UserForm}
  />,

  <Route
    key={'/subscribe/form-submit'}
    path={'/subscribe/form-submit'}
    exact={true}
    component={SubscribeFormSubmit}
  />,

  <Route
    key={'/email-receiver'}
    path={'/email-receiver'}
    exact={true}
    component={EmailReceiver}
  />,
  <Route
    key={'/email-receiver/new'}
    path={'/email-receiver/new'}
    exact={true}
    component={EmailReceiverForm}
  />,
  <Route
    key={'/email-receiver/edit/:_id'}
    path={'/email-receiver/edit/:_id'}
    exact={true}
    component={EmailReceiverForm}
  />,

  <Route
    key={'/line/user'}
    path={'/line/user'}
    exact={true}
    component={LineUser}
  />,
  <Route
    key={'/line/user/new'}
    path={'/line/user/new'}
    exact={true}
    component={LineUserForm}
  />,
  <Route
    key={'/line/user/edit/:_id'}
    path={'/line/user/edit/:_id'}
    exact={true}
    component={LineUserForm}
  />
];

export default { routes };
