import _ from 'lodash';
import axios from 'axios';
import { message } from 'antd';
import { sendPost, sendPatch, sendDelete } from './requestHandle';
import { storageRef } from './firebase';
import {
  randomHex,
  uploadToFirebaseStorageAndSetUrl,
  uploadToServerAndSetPath,
  getNext,
  isFile
} from './global';
import { languages } from './variables';

export const handleLink = url => {
  window.open(url, 'blank');
};

export const handlePath = (history, path) => {
  history.push(path);
};

export const handleNew = (history, path) => {
  handlePath(history, path);
};

export const handleEdit = (history, path) => {
  handlePath(history, path);
};

export const handleBack = (history, path) => {
  handlePath(history, path);
};

export const handleCreate = async (url, data, path, history) => {
  await sendPost(url, data);
  if (path) {
    handlePath(history, path);
  }
};

export const handleUpdate = async (url, data, path, history) => {
  await sendPatch(url, data);
  if (path) {
    handlePath(history, path);
  }
};

export const handleDelete = async (
  url,
  _id,
  data,
  setData,
  childId,
  dataKey
) => {
  if (await sendDelete(url, _id, childId, dataKey)) {
    if (data && setData) {
      const dataAfterDelete = _.filter(data, d => {
        if (childId) {
          return _.get(d, '_id') !== childId;
        } else {
          return _.get(d, '_id') !== _id;
        }
      });
      setData(dataAfterDelete);
    }
  }
};

export const handleSearchChange = (e, fuse, setData, fullData) => {
  if (_.isEmpty(e.target.value)) {
    setData(fullData);
  } else {
    if (fuse && e.target.value) {
      const result = fuse.search(e.target.value);
      setData(result);
    }
  }
};

export const handleSubmit = async (
  url,
  path,
  action,
  inputFields,
  idProps,
  setIsLoading,
  redirectPath,
  history,
  dataKey,
  childId,
  updateOuter
) => {
  let data = {};
  const _id = idProps || randomHex(24);
  data['_id'] = _id;
  let shouldPerformSubmit = true;
  // Validate
  for (let i = 0; i < inputFields.length; i++) {
    const state = _.get(inputFields[i], 'state');
    const isRequired = _.some(_.get(inputFields[i], 'validation'), {
      type: 'required'
    });
    if (isRequired && (!state || state === '')) {
      message.error(
        `Please enter ${_.get(inputFields[i], 'label')} before submit.`
      );
      shouldPerformSubmit = false;
      return;
    }
  }
  // Finish Validate
  setIsLoading(true);
  await Promise.all(
    _.map(inputFields, async input => {
      const key = _.get(input, 'key');
      const state = _.get(input, 'state');
      const realValue = _.get(input, 'realValue');
      const inputType = _.get(input, 'type');
      const multiLang = _.get(input, 'multiLang');
      if (inputType === 'file') {
        if (_.get(input, 'isMultiple')) {
          // Check if input is multiple
          data[key] = [];
          const usedState = state || [];
          let nextOrder = getNext('order', usedState);
          await Promise.all(
            _.map(usedState, async (s, index) => {
              if (_.get(s, 'name')) {
                // Check if state is file
                // const fileRef = storageRef.child(`${path}/${_id}_${key}_${index}_url_${new Date().getTime()}`);
                // await fileRef.put(s).then(async (snapshot) => {
                //   await fileRef.getDownloadURL().then((url) => {
                //     data[key].push({ url: url, title: _.get(s, 'name'), order: nextOrder });
                //     nextOrder += 1;
                //   });
                // });
                let fileFormData = new FormData();
                const fileName = `${path}/${_id}_${key}_${index}_${new Date().getTime()}`;
                fileFormData.append('file', s);
                fileFormData.append('fileName', fileName);
                const response = await sendPost(
                  `${process.env.REACT_APP_API_HOST}/upload`,
                  fileFormData,
                  'multipart/form-data'
                );
                const assetUrl = _.get(response, 'data.data.uploadedUrl');
                data[key].push({
                  url:
                    assetUrl ||
                    `${process.env.REACT_APP_UPLOAD_PATH}/${fileName}`,
                  title: fileName,
                  order: nextOrder
                });
                nextOrder += 1;
              } else {
                data[key].push(s);
              }
            })
          );
        } else if (_.get(input, 'state.name')) {
          // Check if state is file
          // await uploadToFirebaseStorageAndSetUrl(state, `${path}/${_id}_${key}_url_${new Date().getTime()}`, data, key);
          await uploadToServerAndSetPath(
            state,
            `${path}/${_id}_${key}_${new Date().getTime()}`,
            data,
            key
          );
        } else {
          data[key] = state;
        }
      } else if (inputType === 'dynamic') {
        data[key] = state;
        if (multiLang) {
          // const dataToLoop = _.get(state, 'en'); // Use image from en
          // await Promise.all(
          //   _.map(dataToLoop, async (dtl, index) => {
          //     const updatedObjects = [];
          //     _.map(languages, async langObj => {
          //       const language = _.get(langObj, 'key');
          //       let tmpUpdatedObject = data[key][language][index];
          //       if (!tmpUpdatedObject) {
          //         data[key][language][index] = {};
          //       }
          //       updatedObjects.push(data[key][language][index]);
          //     });
          //     const value = _.get(dtl, 'image');
          //     const fileName = _.get(value, 'name');
          //     if (value && fileName) {
          //       // await uploadToFirebaseStorageAndSetUrl(value, `${path}/${_id}_${key}_${index}_image_${new Date().getTime()}`, updatedObjects, 'image', true);
          //       await uploadToServerAndSetPath(
          //         value,
          //         `${path}/${_id}_${key}_${index}_image_${new Date().getTime()}`,
          //         updatedObjects,
          //         'image',
          //         true
          //       );
          //     }
          //   })
          // );
          const dataToLoop = state;
          await Promise.all(
            _.map(dataToLoop, async (dtl, index) => {
              const value = _.get(dtl, 'image.en');
              const fileName = _.get(value, 'name');
              if (value && fileName) {
                // await uploadToFirebaseStorageAndSetUrl(value, `${path}/${_id}_${key}_${index}_image_${new Date().getTime()}`, updatedObjects, 'image', true);
                await uploadToServerAndSetPath(
                  value,
                  `${path}/${_id}_${key}_${index}_image_${new Date().getTime()}`,
                  data[key][index],
                  'image',
                  false,
                  true,
                  languages
                );
              }
            })
          );
        } else {
          const dataToLoop = state;
          await Promise.all(
            _.map(dataToLoop, async (dtl, index) => {
              const value = _.get(dtl, 'image');
              console.log('image value', value);
              const fileName = _.get(value, 'name');
              if (value && fileName) {
                // await uploadToFirebaseStorageAndSetUrl(value, `${path}/${_id}_${key}_${index}_image_${new Date().getTime()}`, data[key][index], 'image');
                await uploadToServerAndSetPath(
                  value,
                  `${path}/${_id}_${key}_${index}_image_${new Date().getTime()}`,
                  data[key][index],
                  'image'
                );
              }
            })
          );
        }
      } else if (inputType === 'select') {
        if (realValue) {
          data[key] = realValue;
        } else {
          data[key] = state;
        }
      } else {
        data[key] = state;
      }
    })
  );
  if (shouldPerformSubmit) {
    const _id = _.get(data, '_id');
    if (dataKey && _id) {
      delete data['_id'];
      data = { _id: _id, [dataKey]: data };
    }
    if (dataKey) {
      data['dataKey'] = dataKey;
    }
    if (childId) {
      data['childId'] = childId;
    }
    if (updateOuter) {
      data['updateOuter'] = updateOuter;
    }
    console.log('data before submit', data);
    switch (_.toLower(action)) {
      case 'new':
        await handleCreate(url, data, redirectPath, history);
        setIsLoading(false);
        message.success('Create success');
        break;
      case 'edit':
        await handleUpdate(url, data, redirectPath, history);
        setIsLoading(false);
        message.success('Update success');
        break;
      default:
        break;
    }
  }
};

export const handleLoginSubmit = async (url, email, password) => {
  const data = {
    email,
    password
  };
  const result = await sendPost(url, data);
  if (_.get(result, 'data.status') === 'success') {
    localStorage.setItem('isLogin', 'true');
    localStorage.setItem(
      'allowPaths',
      JSON.stringify(_.get(result, 'data.data.allowPaths'))
    );
    window.location.href = '/';
  } else {
    message.error('Wrong email or password.');
  }
};

export const handleLogout = () => {
  localStorage.setItem('isLogin', 'false');
  window.location.reload();
};
