import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import {
  backgroundTypeOptions,
  projectSectionNames
} from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import {
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../../utils/global';

const rootPath = 'project';
const dataKey = 'register';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const childId = _.get(props, 'match.params.child_id');
  const projectEditPath = `/project/edit/${_id}`;
  const path = `project/edit/${_id}/register`;
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState();
  const [backgroundType, setBackgroundType] = useState('Image');
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [textColor, setTextColor] = useState();
  const [budgets, setBudgets] = useState();
  const [budgetOptions, setBudgetOptions] = useState();
  const [roomTypes, setRoomTypes] = useState();
  const [roomTypeOptions, setRoomTypeOptions] = useState();
  const [forceUpdate, setForceUpdate] = useState();
  const [showSections, setShowSections] = useState();
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: projectEditPath }
  ];
  appendSectionSelectOptions(
    sectionSelectOptions,
    showSections,
    projectEditPath
  );
  sortArrayWithKey(sectionSelectOptions, 'order');
  const inputFields = [
    {
      key: 'sectionSelect',
      label: 'Sections',
      type: 'sectionSelect',
      defaultValue: `/${path}`,
      sections: sectionSelectOptions
    },
    {
      key: 'title',
      state: title,
      setState: setTitle,
      label: 'Title',
      multiLang: true,
      type: 'text'
    },
    {
      key: 'backgroundType',
      state: backgroundType,
      setState: setBackgroundType,
      label: 'Background Type',
      type: 'select',
      valuesForSelect: backgroundTypeOptions
    },
    {
      key: 'backgroundImage',
      state: backgroundImage,
      setState: setBackgroundImage,
      label: 'Background Image',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'image',
      maxFileSize: 5,
      resolution: '1920x1080',
      previewWidth: '400px'
    },
    {
      key: 'backgroundColor',
      state: backgroundColor,
      setState: setBackgroundColor,
      label: 'Background Color',
      type: 'colorPicker'
    },
    {
      key: 'textColor',
      state: textColor,
      setState: setTextColor,
      label: 'Text Color',
      type: 'colorPicker'
    },
    {
      key: 'budgets',
      state: budgets,
      setState: setBudgets,
      label: 'Budget',
      type: 'transfer',
      renderKey: 'title',
      valueKey: '_id',
      valuesForDataSource: budgetOptions
    },
    {
      key: 'roomTypes',
      state: roomTypes,
      setState: setRoomTypes,
      label: 'Room Type',
      type: 'transfer',
      renderKey: 'title',
      valueKey: '_id',
      valuesForDataSource: roomTypeOptions
    }
  ];
  useEffect(() => {
    setIsLoading(true);
    async function fetchOptionsForTransfer(url, setOptionsForTransfer) {
      const result = await sendGet(url);
      const data = _.get(result, 'data.data');
      const options = [];
      _.map(data, (d, index) => {
        const titleEn = _.get(d, 'title.en');
        const _id = _.get(d, '_id');
        options.push({
          _id: _id,
          title: titleEn
        });
      });
      setOptionsForTransfer(options);
    }
    fetchOptionsForTransfer(
      `${process.env.REACT_APP_API_HOST}/project/budget/list`,
      setBudgetOptions
    );
    fetchOptionsForTransfer(
      `${process.env.REACT_APP_API_HOST}/project/room-type/list`,
      setRoomTypeOptions
    );
    if (action === 'Edit') {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data =
          dataKey !== ''
            ? _.get(result, `data.data.${dataKey}`, [])
            : _.get(result, 'data.data', []);
        setShowSections(_.get(result, 'data.data.showSections'));
        setTitle(_.get(data, 'title'));
        setBackgroundType(_.get(data, 'backgroundType', 'Image'));
        setBackgroundImage(_.get(data, 'backgroundImage'));
        setBackgroundColor(_.get(data, 'backgroundColor'));
        setTextColor(_.get(data, 'textColor'));
        setBudgets(_.get(data, 'budgets'));
        setRoomTypes(_.get(data, 'roomTypes'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
    } else {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        setShowSections(_.get(result, 'data.data.showSections'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Register Section`}
        inputFields={inputFields}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: dataKey,
            childId: childId
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);