import React from 'react';
import _ from 'lodash';
import ReactExport from 'react-data-export';
import { withRouter } from 'react-router-dom';
import { Icon, Popconfirm, Button } from 'antd';

import Styled from './styled';
import {
  handleNew,
  handleEdit,
  handleDelete,
  handleSubmit,
  handleBack
} from '../../utils/actionHandle';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default {
  NewButton: withRouter(function NewButton(props) {
    return (
      <Styled>
        <Button
          type='primary'
          onClick={() =>
            handleNew(_.get(props, 'history'), _.get(props, 'path'))
          }
          className='new-button'
        >
          <Icon type='plus' style={{ marginRight: '5px' }} />
          {_.get(props, 'title') || 'Create'}
        </Button>
      </Styled>
    );
  }),
  EditButton: withRouter(function EditButton(props) {
    return (
      <Styled>
        <Icon
          type='edit'
          className='edit-button'
          onClick={() =>
            handleEdit(_.get(props, 'history'), _.get(props, 'path'))
          }
        />
      </Styled>
    );
  }),
  DeleteButton: function DeleteButton(props) {
    return (
      <Styled>
        <Popconfirm
          title={_.get(props, 'title') || 'Confirm to delete?'}
          onConfirm={() =>
            handleDelete(
              _.get(props, 'url'),
              _.get(props, '_id'),
              _.get(props, 'data'),
              _.get(props, 'setData'),
              _.get(props, 'childId'),
              _.get(props, 'dataKey')
            )
          }
        >
          <Icon type='delete' className='delete-button' />
        </Popconfirm>
      </Styled>
    );
  },
  SubmitButton: withRouter(function SubmitButton(props) {
    return (
      <Styled>
        <Button
          type='primary'
          onClick={() =>
            handleSubmit(
              _.get(props, 'url'),
              _.get(props, 'path'),
              _.get(props, 'action'),
              _.get(props, 'inputFields'),
              _.get(props, '_id'),
              _.get(props, 'setIsLoading'),
              _.get(props, 'redirectPath', `/${_.get(props, 'path')}`),
              _.get(props, 'history'),
              _.get(props, 'dataKey'),
              _.get(props, 'childId'),
              _.get(props, 'updateOuter')
            )
          }
          className='submit-button'
        >
          {_.get(props, 'title') || 'Submit'}
        </Button>
      </Styled>
    );
  }),
  BackButton: withRouter(function BackButton(props) {
    return (
      <Styled>
        <Button
          type='primary'
          onClick={() =>
            handleBack(_.get(props, 'history'), _.get(props, 'path'))
          }
          className='back-button'
        >
          {_.get(props, 'title') || 'Back'}
        </Button>
      </Styled>
    );
  }),
  DownloadExcelButton: withRouter(function DownloadExcelButton(props) {
    return (
      <Styled>
        <ExcelFile
          filename={_.get(props, 'fileName')}
          element={
            <Button type='primary' className='download-excel-button'>
              <Icon type='download' /> Excel
            </Button>
          }
        >
          {_.get(props, 'excelMultiDataset') && (
            <ExcelSheet
              dataSet={_.get(props, 'excelMultiDataset')}
              name={_.get(props, 'sheetName')}
            >
              {/* {_.map(_.get(props, 'columns', []), (column, index) => {
                return (
                  <ExcelColumn
                    label={_.get(column, 'label')}
                    value={_.get(column, 'value')}
                  />
                );
              })} */}
            </ExcelSheet>
          )}
        </ExcelFile>
      </Styled>
    );
  })
};
