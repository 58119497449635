import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../components/CustomForm';
import { sendGet } from '../../utils/requestHandle';

const path = 'policy';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState();
  const [fullDescription, setFullDescription] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  useEffect(() => {
    if (action === 'Edit') {
      setIsLoading(true);
      async function fetchDoc(url) {
        const result = await sendGet(url);
        set_id(_.get(result, 'data.data._id'));
        const data = _.get(result, 'data.data');
        setTitle(_.get(data, 'title'));
        setFullDescription(_.get(data, 'fullDescription'));
        setBackgroundImage(_.get(data, 'backgroundImage'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/first-doc`);
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Policy`}
        inputFields={[
          {
            key: 'title',
            state: title,
            setState: setTitle,
            multiLang: true,
            label: 'Title',
            type: 'text'
          },
          {
            key: 'fullDescription',
            state: fullDescription,
            setState: setFullDescription,
            multiLang: true,
            label: 'Full Description',
            placeholder: 'Enter Full Description',
            type: 'editor',
            action: action,
            height: '700px'
          },
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x300',
            previewWidth: '400px'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}/edit`
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
