import * as firebase from 'firebase/app';

import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyD0JbwrpbdfgSBmQ5hLiE2hfG5PoHKzqzs',
  projectId: 'test-firebase-app-cbff1',
  storageBucket: 'test-firebase-app-cbff1.appspot.com'
};

firebase.initializeApp(firebaseConfig);

export const storageRef = firebase.storage().ref();

export default firebase;
