import styled from 'styled-components';
import _ from 'lodash';
import { sideMenuWidth } from '../../../utils/variables';

export default styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .card {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
  }
  .text-header {
    color: #5651518a;
    font-size: 1.3rem;
    text-align: center;
  }
  .form-row {
    margin: 0 10px 15px 10px;
  }
  .form-feedback {
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
  }
  .submit-btn {
    width: 100%;
  }
  #username {
    margin-top: 10px;
  }
  input::placeholder {
    color: #c1c1c1;
  }
  .login-form {
    width: 300px;
    margin: 0 auto;
    font-family: Tahoma, Geneva, sans-serif;
  }
  .login-form h1 {
    text-align: center;
    color: #4d4d4d;
    font-size: 24px;
    padding: 20px 0 20px 0;
  }
  .login-form input[type='password'],
  .login-form input[type='text'] {
    width: 100%;
    padding: 15px;
    border: 1px solid #dddddd;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  .login-submit-button {
    width: 100%;
    padding: 15px;
    background-color: #535b63;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;
    color: #ffffff;
  }
`;
