import React, { useState } from 'react';
import {
  Input,
  Select,
  Checkbox,
  DatePicker,
  Form,
  Icon,
  Transfer,
  Radio,
  Switch,
} from 'antd';
import _ from 'lodash';
import { ChromePicker } from 'react-color';
import moment from 'moment';
import CKEditor from 'ckeditor4-react';

import Styled from './styled';
import {
  handleTextChange,
  handleValueChange,
  handleNumberChange,
  handleDateRangeChange,
  handleSwitchChange,
  handleSelectChange,
  handleCheckChange,
  handleDateChange,
  handleColorPickerChange,
  handleSectionSelectChange,
  handleTransferChange,
  handleEditorChange,
  handleFileChange,
  handleDynamicTextChange,
  handleDynamicFileChange,
  handleDynamicFileClear,
  handleNewDynamicInput,
  handleDeleteDynamicInput,
} from '../../utils/formInputHandle';
import {
  showModalFullImageFromFile,
  showModalFullImageFromUrl,
  previewImage,
  previewImageWithIdList,
  appendPreviewFile,
  appendPreviewImage,
  deleteFileWithUrl,
} from '../../utils/global';
import { languages } from '../../utils/variables';

const { Option } = Select;
const { RangePicker } = DatePicker;

export function TextInput(props) {
  const elementKey = _.get(props, 'elementKey');
  const lang = _.get(props, 'lang');
  const value = _.get(props, 'input.multiLang')
    ? _.get(props, `input.state.${lang}`)
    : _.get(props, 'input.state');
  const isRequired = _.some(_.get(props, 'input.validation'), {
    type: 'required',
  });
  return (
    <Styled>
      <div className='input-container'>
        <label htmlFor={elementKey} className='input-label'>
          {_.get(props, 'input.label')}
        </label>
        <Form.Item
          hasFeedback={isRequired}
          validateStatus={
            isRequired && (!value || value === '') ? 'error' : 'success'
          }
          help={isRequired && (!value || value === '') ? 'Required' : ''}
        >
          <Input
            id={elementKey}
            name={elementKey}
            value={value}
            placeholder={
              _.get(props, 'input.placeholder') ||
              `Enter ${_.get(props, 'input.label')}`
            }
            onChange={(e) =>
              handleTextChange(
                e,
                _.get(props, 'input.state'),
                _.get(props, 'input.setState'),
                _.get(props, 'input.multiLang'),
                lang
              )
            }
          />
        </Form.Item>
      </div>
    </Styled>
  );
}

export function PasswordInput(props) {
  const elementKey = _.get(props, 'elementKey');
  const value = _.get(props, 'input.state');
  console.log('value', value);
  const isRequired = _.some(_.get(props, 'input.validation'), {
    type: 'required',
  });
  return (
    <Styled>
      <div className='input-container'>
        <label htmlFor={elementKey} className='input-label'>
          {_.get(props, 'input.label')}
        </label>
        <Form.Item
          hasFeedback={isRequired}
          validateStatus={
            isRequired && (!value || value === '') ? 'error' : 'success'
          }
          help={isRequired && (!value || value === '') ? 'Required' : ''}
        >
          <Input
            type='password'
            id={elementKey}
            name={elementKey}
            value={value}
            placeholder={
              _.get(props, 'input.placeholder') ||
              `Enter ${_.get(props, 'input.label')}`
            }
            onChange={(e) =>
              handleTextChange(
                e,
                _.get(props, 'input.state'),
                _.get(props, 'input.setState')
              )
            }
          />
        </Form.Item>
      </div>
    </Styled>
  );
}
export function NumberInput(props) {
  const elementKey = _.get(props, 'elementKey');
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{_.get(props, 'input.label')}</label>
        <Input
          type='number'
          id={elementKey}
          name={elementKey}
          value={_.get(props, 'input.state')}
          placeholder={
            _.get(props, 'input.placeholder') ||
            `Enter ${_.get(props, 'input.label')}`
          }
          onChange={(e) =>
            handleNumberChange(e, _.get(props, 'input.setState'))
          }
        />
      </div>
    </Styled>
  );
}

export function DateRangeInput(props) {
  const elementKey = _.get(props, 'elementKey');
  const filteredField = _.get(props, 'input.filteredField');
  const hideContainer = _.get(props, 'input.hideContainer');
  const hideLabel = _.get(props, 'input.hideLabel');
  const fullState = _.get(props, 'input.fullState');
  const setState = _.get(props, 'input.setState');
  return (
    <Styled>
      <div className={hideContainer ? '' : 'input-container'}>
        {!hideLabel && (
          <label className='input-label'>{_.get(props, 'input.label')}</label>
        )}
        <RangePicker
          id={elementKey}
          ranges={{
            Today: [moment(), moment().endOf('day')],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
          }}
          showTime
          format='YYYY/MM/DD HH:mm:ss'
          onChange={(dates, dateStrings) =>
            handleDateRangeChange(
              dates,
              dateStrings,
              fullState,
              setState,
              filteredField
            )
          }
          style={{ marginRight: '10px', width: '400px' }}
        />
      </div>
    </Styled>
  );
}

export function SwitchInput(props) {
  const setForceUpdate = _.get(props, 'input.setForceUpdate');
  const state = _.get(props, 'input.state');
  const setState = _.get(props, 'input.setState');
  const key = _.get(props, 'input.key');
  const childKey = _.get(props, 'input.childKey');
  const label = _.get(props, 'input.label');
  const checked = childKey ? _.get(state, childKey) : state;
  return (
    <Styled>
      <div className='switch-container'>
        <Switch
          id={`${key}-${label}`}
          name={`${key}-${label}`}
          checked={checked}
          onChange={(e) =>
            handleSwitchChange(e, setState, childKey, state, setForceUpdate)
          }
        />
        <label className='input-label' style={{ margin: '0 10px 0 5px' }}>
          {label}
        </label>
      </div>
    </Styled>
  );
}

export function TextAreaInput(props) {
  const elementKey = _.get(props, 'elementKey');
  const lang = _.get(props, 'lang');
  const value = _.get(props, 'input.multiLang')
    ? _.get(props, `input.state.${lang}`)
    : _.get(props, 'input.state');
  const isRequired = _.some(_.get(props, 'input.validation'), {
    type: 'required',
  });
  return (
    <Styled>
      <div className='input-container'>
        <label htmlFor={elementKey} className='input-label'>
          {_.get(props, 'input.label')}
        </label>
        <Form.Item
          hasFeedback={isRequired}
          validateStatus={
            isRequired && !value && value === '' ? 'error' : 'success'
          }
          help={isRequired && (!value || value === '') ? 'Required' : ''}
        >
          <Input.TextArea
            id={elementKey}
            name={elementKey}
            value={
              _.get(props, 'input.multiLang')
                ? _.get(props, `input.state.${lang}`)
                : _.get(props, 'input.state')
            }
            placeholder={
              _.get(props, 'input.placeholder') ||
              `Enter ${_.get(props, 'input.label')}`
            }
            rows={_.get(props, 'input.rows') || 4}
            onChange={(e) =>
              handleTextChange(
                e,
                _.get(props, 'input.state'),
                _.get(props, 'input.setState'),
                _.get(props, 'input.multiLang'),
                lang
              )
            }
          />
        </Form.Item>
      </div>
    </Styled>
  );
}

export function SelectInput(props) {
  const elementKey = _.get(props, 'elementKey');
  const setState = _.get(props, 'input.setState');
  const valuesForSelect = _.get(props, 'input.valuesForSelect');
  const fullOptions = _.get(props, 'input.fullOptions');
  const setRealValue = _.get(props, 'input.setRealValue');
  const realValueKeys = _.get(props, 'input.realValueKeys');
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{_.get(props, 'input.label')}</label>
        <Select
          id={elementKey}
          name={elementKey}
          value={_.get(props, 'input.state')}
          onChange={(e) =>
            handleSelectChange(
              e,
              setState,
              fullOptions,
              setRealValue,
              realValueKeys
            )
          }
        >
          {_.map(valuesForSelect, (option) => {
            return (
              <Option
                key={_.get(option, 'title')}
                value={_.get(option, 'value')}
              >
                {_.get(option, 'title')}
              </Option>
            );
          })}
        </Select>
      </div>
    </Styled>
  );
}

export function CheckInput(props) {
  const state = _.get(props, 'input.state');
  return (
    <Styled>
      <div className='input-container'>
        <Checkbox
          checked={state}
          onChange={(e) => handleCheckChange(e, _.get(props, 'input.setState'))}
        >
          {_.get(props, 'input.label')}
        </Checkbox>
      </div>
    </Styled>
  );
}

export function DateInput(props) {
  const state = _.get(props, 'input.state');
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{_.get(props, 'input.label')}</label>
        <DatePicker
          value={
            state
              ? state instanceof Date
                ? moment(state)
                : moment(new Date(state))
              : moment(new Date())
          }
          onChange={(date, dateString) =>
            handleDateChange(date, dateString, _.get(props, 'input.setState'))
          }
        />
      </div>
    </Styled>
  );
}

export function ColorPickerInput(props) {
  const elementKey = _.get(props, 'elementKey');
  const state = _.get(props, 'input.state');
  const setState = _.get(props, 'input.setState');
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{_.get(props, 'input.label')}</label>
        <ChromePicker
          name={elementKey}
          color={state}
          onChangeComplete={(e) => handleColorPickerChange(e, setState)}
        />
      </div>
    </Styled>
  );
}

export function SectionSelectInput(props) {
  return (
    <Styled>
      <div className='input-container' style={{ flexDirection: 'row' }}>
        <Radio.Group
          onChange={(e) =>
            handleSectionSelectChange(e, _.get(props, 'history'))
          }
          defaultValue={_.get(props, 'input.defaultValue')}
          buttonStyle='solid'
        >
          {_.map(_.get(props, 'input.sections'), (section) => {
            return (
              <Radio.Button value={_.get(section, 'value')}>
                {_.get(section, 'name')}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    </Styled>
  );
}

export function TransferInput(props) {
  const valuesForDataSource = _.get(props, 'input.valuesForDataSource');
  const valueKey = _.get(props, 'input.valueKey', 'key');
  const renderKey = _.get(props, 'input.renderKey', 'label');
  const state = _.get(props, 'input.state');
  const setState = _.get(props, 'input.setState');
  const filterLogic = (inputValue, option) => {
    return _.toLower(_.get(option, 'title', '')).includes(
      _.toLower(inputValue)
    );
  };
  const generateTargetKeys = (state) => {
    if (state) {
      const result = [];
      _.map(state, (s) => {
        result.push(_.get(s, valueKey));
      });
      return result;
    }
  };
  const targetKeys = generateTargetKeys(state);
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{_.get(props, 'input.label')}</label>
        <Transfer
          rowKey={(record) => _.get(record, valueKey)}
          dataSource={valuesForDataSource}
          showSearch
          filterOption={filterLogic}
          listStyle={{
            width: 250,
            height: 300,
          }}
          targetKeys={targetKeys}
          onChange={handleTransferChange(
            valuesForDataSource,
            valueKey,
            setState
          )}
          render={(item) => _.get(item, renderKey)}
        />
      </div>
    </Styled>
  );
}

export function EditorInput(props) {
  const lang = _.get(props, 'lang');
  const state = _.get(props, 'input.state');
  const value = _.get(props, 'input.multiLang')
    ? _.get(props, `input.state.${lang}`)
    : state;
  const setState = _.get(props, 'input.setState');
  const multiLang = _.get(props, 'input.multiLang');
  const action = _.get(props, 'input.action');
  const height = _.get(props, 'input.height', '250px');
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{_.get(props, 'input.label')}</label>
        {((action === 'Edit' && value) || action !== 'Edit') && (
          <CKEditor
            data={value}
            onChange={(e) =>
              handleEditorChange(e, state, setState, multiLang, lang)
            }
            onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
            config={{
              height,
              // filebrowserBrowseUrl: `${process.env.REACT_APP_API_HOST}/upload/editor-content`,
              // filebrowserUploadUrl: `${process.env.REACT_APP_API_HOST}/upload/editor-content`
              // toolbar: [ [ 'Bold' ] ],
              // extraPlugins: 'easyimage',
              // removePlugins: 'image',
              // cloudServices_uploadUrl: `${process.env.REACT_APP_API_HOST}/upload/editor-content`
            }}
            // timestamp={"ABCD"}
            // onInstanceReady={!state ? alert('not have') : alert('state')}
          />
        )}
      </div>
    </Styled>
  );
}

export function FileInput(props) {
  const setForceUpdate = _.get(props, 'input.setForceUpdate');
  const isMultiple = _.get(props, 'input.isMultiple');
  const state = _.get(props, 'input.state', isMultiple ? [] : '');
  const setState = _.get(props, 'input.setState');
  const key = _.get(props, 'input.key');
  const label = _.get(props, 'input.label');
  const fileName = _.get(state, 'name');
  const previewType = _.get(props, 'input.previewType');
  const previewWidth = _.get(props, 'input.previewWidth', '200px');
  const shouldPreviewFile = previewType === 'file';
  const shouldPreviewImage = previewType === 'image';
  const shouldPreviewFileList = previewType === 'file-list';
  const shouldPreviewImageList = previewType === 'image-list';
  const resolution = _.get(props, 'input.resolution');
  const maxFileSizeMb = _.toNumber(_.get(props, 'input.maxFileSize', 2));
  const maxFileSizeByte = _.toNumber(maxFileSizeMb) * 1048576;
  const [isFileOverLimit, setIsFileOverLimit] = useState(false);
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{label}</label>
        <Input
          multiple={isMultiple}
          onChange={(e) =>
            handleFileChange(
              e,
              state,
              setState,
              setIsFileOverLimit,
              maxFileSizeByte,
              isMultiple
            )
          }
          type='file'
          name={key}
          id={key}
        />
        {maxFileSizeMb && (
          <div className='file-condition-text'>
            * Max file size {maxFileSizeMb}MB <br />
          </div>
        )}
        {resolution && (
          <div className='file-condition-text'>
            * Resolution {resolution} <br />
          </div>
        )}
        {(() => {
          return (
            isFileOverLimit && (
              <React.Fragment>
                <p style={{ color: 'red' }}>
                  * Please upload file size less than {maxFileSizeMb || 2}MB
                </p>
              </React.Fragment>
            )
          );
        })()}
        {shouldPreviewFile && !isMultiple && state && (
          <div>
            <a
              href={process.env.REACT_APP_API_HOST + state}
              style={{
                pointerEvents: fileName ? 'none' : 'all',
                color: fileName ? '#595959' : '#957e53',
              }}
              id={`preview-file-${key}`}
              target='_blank'
            >
              {fileName || label}
            </a>
            <Icon
              type='delete'
              className='delete-icon'
              onClick={() => {
                setState('');
                deleteFileWithUrl(state);
              }}
            />
          </div>
        )}
        {shouldPreviewImage && !isMultiple && state && (
          <div>
            <img
              onClick={() =>
                fileName
                  ? showModalFullImageFromFile(state)
                  : showModalFullImageFromUrl(state)
              }
              id={`preview-image-${key}`}
              style={{
                width: previewWidth,
                height: 'auto',
                margin: '10px 5px 0 0',
                backgroundColor: '#ddd2ca',
              }}
              src={process.env.REACT_APP_API_HOST + state}
              alt=''
            />
            <Icon
              type='delete'
              className='delete-icon'
              onClick={() => {
                setState('');
                deleteFileWithUrl(state);
              }}
            />
            {fileName && previewImage(state, `preview-image-${key}`)}
          </div>
        )}
        {(shouldPreviewImageList || shouldPreviewFileList) &&
          isMultiple &&
          state && (
            <div id={`preview-${previewType}-container-${key}`}>
              {_.map(state, (s, index) => {
                let isFile = false;
                let name = _.get(s, 'title');
                let value = _.get(s, 'url');
                if (!value) {
                  isFile = true;
                  name = _.get(s, 'name');
                  value = _.get(s, 'name');
                }
                if (shouldPreviewImageList) {
                  appendPreviewImage(
                    key,
                    index,
                    name,
                    value,
                    isFile,
                    state,
                    setState,
                    previewWidth,
                    setForceUpdate
                  );
                  isFile &&
                    previewImage(s, `preview-${previewType}-${key}-${name}`);
                } else {
                  appendPreviewFile(
                    key,
                    index,
                    name,
                    value,
                    isFile,
                    state,
                    setState,
                    setForceUpdate
                  );
                }
              })}
            </div>
          )}
      </div>
    </Styled>
  );
}

export function DynamicInput(props) {
  const hideImageInput = _.get(props, 'input.hideImageInput');
  const key = _.get(props, 'input.key');
  const label = _.get(props, 'input.label');
  const inline = _.get(props, 'input.inline');
  const state = _.get(props, 'input.state');
  const setState = _.get(props, 'input.setState');
  const setForceUpdate = _.get(props, 'input.setForceUpdate');
  const lang = _.get(props, 'lang');
  const multiLang = _.get(props, 'input.multiLang');
  const hideDescription = _.get(props, 'input.hideDescription');
  const resolution = _.get(props, 'input.resolution');

  // if (!state) {
  //   const defaultState = multiLang ? {en:[{}],th:[{}]} : [{}];
  //   setState(defaultState);
  // }
  if (!state) {
    const defaultState = [{}];
    setState(defaultState);
  }
  // const stateToRender = multiLang ? _.get(state, lang) : state;
  const stateToRender = state;
  return (
    <Styled>
      <div className='input-container'>
        <label className='input-label'>{label}</label>
        <div className='dynamic-input-container'>
          {_.map(stateToRender, (s, index) => {
            const imageLabel = `Image ${index + 1}`;
            const imageKey = 'image';
            const inputImageId = `${key}-en-${imageKey}-${index}`;
            const previewImageId = `preview-image-${key}-${lang}-${index}`;
            // const imageValue = multiLang ? _.get(state, `en.${index}.${imageKey}`) : _.get(s, imageKey);
            let imageValue = multiLang
              ? _.get(s, `${imageKey}.en`)
              : _.get(s, imageKey);
            const fileName = _.get(imageValue, 'name');
            if (_.isUndefined(fileName)) {
              imageValue = process.env.REACT_APP_API_HOST + imageValue;
            }
            const previewImageIdList = [];
            _.map(languages, (langObj) => {
              const language = _.get(langObj, 'key');
              previewImageIdList.push(
                `preview-image-${key}-${language}-${index}`
              );
            });

            const titleLabel = `Title ${index + 1}`;
            const titlePlaceholder = `Enter title ${index + 1}`;
            const titleKey = 'title';
            const inputTitleId = `${key}-${lang}-${titleKey}-${index}`;
            // const titleValue = _.get(s, titleKey);
            const titleValue = multiLang
              ? _.get(s, `${titleKey}.${lang}`)
              : _.get(s, `${titleKey}`);

            const descriptionLabel = `Description ${index + 1}`;
            const descriptionPlaceholder = `Enter description ${index + 1}`;
            const descriptionKey = 'description';
            const inputDescriptionId = `${key}-${lang}-${descriptionKey}-${index}`;
            // const descriptionValue = _.get(s, descriptionKey);
            const descriptionValue = multiLang
              ? _.get(s, `${descriptionKey}.${lang}`)
              : _.get(s, `${descriptionKey}`);

            return (
              <div className={`dynamic-input-single-container-${key}-${index}`}>
                {!hideImageInput && (
                  <div className='dynamic-input-left'>
                    <label htmlFor={imageKey} className='input-label'>
                      {imageLabel}
                    </label>
                    <Input
                      onChange={(e) =>
                        handleDynamicFileChange(
                          e,
                          imageKey,
                          index,
                          state,
                          setState,
                          multiLang,
                          setForceUpdate
                        )
                      }
                      type='file'
                      name={inputImageId}
                      id={inputImageId}
                    />
                    {resolution && (
                      <div className='file-condition-text'>
                        * Resolution {resolution} <br />
                      </div>
                    )}
                    {imageValue && (
                      <div>
                        <img
                          onClick={() =>
                            fileName
                              ? showModalFullImageFromFile(imageValue)
                              : showModalFullImageFromUrl(imageValue)
                          }
                          id={previewImageId}
                          style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            margin: '10px 5px 0 0',
                            backgroundColor: '#ddd2ca',
                          }}
                          src={imageValue}
                          alt=''
                        />
                        <Icon
                          type='delete'
                          className='delete-icon'
                          onClick={() =>
                            handleDynamicFileClear(
                              imageKey,
                              index,
                              state,
                              setState,
                              multiLang,
                              lang,
                              setForceUpdate
                            )
                          }
                        />
                        {fileName &&
                          previewImageWithIdList(
                            imageValue,
                            previewImageIdList
                          )}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={
                    inline
                      ? 'dynamic-input-right-inline'
                      : 'dynamic-input-right'
                  }
                >
                  <div className={inline ? 'title-inline' : ''}>
                    <label htmlFor={titleKey} className='input-label'>
                      {titleLabel}
                    </label>
                    <Input
                      id={inputTitleId}
                      name={inputTitleId}
                      value={titleValue}
                      placeholder={titlePlaceholder}
                      onChange={(e) =>
                        handleDynamicTextChange(
                          e,
                          titleKey,
                          index,
                          state,
                          setState,
                          multiLang,
                          lang,
                          setForceUpdate
                        )
                      }
                    />
                  </div>

                  {!hideDescription && (
                    <div className={inline ? 'description-inline' : ''}>
                      <div className='margin-top-5' />
                      <label htmlFor={descriptionKey} className='input-label'>
                        {descriptionLabel}
                      </label>
                      <Input
                        id={inputDescriptionId}
                        name={inputDescriptionId}
                        value={descriptionValue}
                        placeholder={descriptionPlaceholder}
                        onChange={(e) =>
                          handleDynamicTextChange(
                            e,
                            descriptionKey,
                            index,
                            state,
                            setState,
                            multiLang,
                            lang,
                            setForceUpdate
                          )
                        }
                      />
                      <Icon
                        onClick={() =>
                          handleDeleteDynamicInput(
                            state,
                            setState,
                            multiLang,
                            index,
                            setForceUpdate
                          )
                        }
                        className='dynamic-single-delete-icon'
                        type='delete'
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <Icon
            onClick={() =>
              handleNewDynamicInput(state, setState, multiLang, setForceUpdate)
            }
            className='plus-square-icon'
            type='plus-square'
            theme='filled'
            fill='currentColor'
          />
        </div>
      </div>
    </Styled>
  );
}
