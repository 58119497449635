import React, { useState } from 'react';
import _ from 'lodash';

import Styled from './styled';
import { handleLoginSubmit } from '../../../utils/actionHandle';
import { handleTextChange } from '../../../utils/formInputHandle';

function UserLogin(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  return (
    <Styled>
      <div class='login-form'>
        <h1>Login</h1>
        <div>
          <input
            type='text'
            name='email'
            placeholder='Email'
            onChange={e => handleTextChange(e, email, setEmail)}
            value={email}
            required
          />
          <input
            type='password'
            name='password'
            placeholder='Password'
            onChange={e => handleTextChange(e, password, setPassword)}
            value={password}
            required
          />
          <button
            className='login-submit-button'
            onClick={() =>
              handleLoginSubmit(
                `${process.env.REACT_APP_API_HOST}/user/auth`,
                email,
                password
              )
            }
          >
            Submit
          </button>
        </div>
      </div>
    </Styled>
  );
}

export default UserLogin;
