import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { sendGet } from '../../../utils/requestHandle';
import { statusOptions } from '../../../utils/variables';

const rootPath = 'contact-us';
const path = 'contact-us';
const dataKey = 'map';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [graphicMap, setGraphicMap] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  useEffect(() => {
    // if (action === 'Edit') {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      set_id(_.get(result, 'data.data._id'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      setGraphicMap(_.get(data, 'graphicMap'));
      setLat(_.get(data, 'lat'));
      setLng(_.get(data, 'lng'));
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
    // }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Map`}
        inputFields={[
          {
            key: 'graphicMap',
            state: graphicMap,
            setState: setGraphicMap,
            label: 'Graphic Map',
            type: 'file',
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x1080',
            previewWidth: '400px'
          },
          {
            key: 'lat',
            state: lat,
            setState: setLat,
            label: 'Latitude',
            type: 'text'
          },
          {
            key: 'lng',
            state: lng,
            setState: setLng,
            label: 'Longitude',
            type: 'text'
          }
        ]}
        actionComponents={[
          { type: 'back', title: 'Back', path: `/${path}` },
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}/map/edit`,
            dataKey: dataKey
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
