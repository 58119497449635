import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { statusOptions, currentDate } from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import { fetchNextOrderOfCollection } from '../../../utils/global';

const path = 'promotion/article';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState();
  const [linkOut, setLinkOut] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [fullDescription, setFullDescription] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [gallery, setGallery] = useState();
  const [publishedDate, setPublishedDate] = useState(currentDate);
  const [isHighlight, setIsHighlight] = useState(false);
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  const [forceUpdate, setForceUpdate] = useState();
  useEffect(() => {
    setIsLoading(true);
    if (action === 'Edit') {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        setTitle(_.get(data, 'title'));
        setLinkOut(_.get(data, 'linkOut'));
        setShortDescription(_.get(data, 'shortDescription'));
        setFullDescription(_.get(data, 'fullDescription'));
        setThumbnail(_.get(data, 'thumbnail'));
        setBannerImage(_.get(data, 'bannerImage'));
        setGallery(_.get(data, 'gallery'));
        setPublishedDate(_.get(data, 'publishedDate', currentDate));
        setIsHighlight(_.get(data, 'isHighlight'));
        setStatus(_.get(data, 'status', 'Show'));
        setOrder(_.get(data, 'order', 1));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/view/${_id}`);
    } else {
      fetchNextOrderOfCollection(
        `${process.env.REACT_APP_API_HOST}/${path}/max/order`,
        setOrder,
        setIsLoading
      );
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Promotion`}
        inputFields={[
          {
            key: 'title',
            state: title,
            setState: setTitle,
            multiLang: true,
            label: 'Title',
            type: 'text',
            validation: [{ type: 'required' }]
          },
          {
            key: 'linkOut',
            state: linkOut,
            setState: setLinkOut,
            label: 'Link Out',
            type: 'text'
          },
          {
            key: 'shortDescription',
            state: shortDescription,
            setState: setShortDescription,
            multiLang: true,
            label: 'Short Description',
            type: 'textarea'
          },
          {
            key: 'fullDescription',
            state: fullDescription,
            setState: setFullDescription,
            multiLang: true,
            label: 'Full Description',
            placeholder: 'Enter Full Description',
            type: 'editor',
            action: action,
            height: '500px'
          },
          {
            key: 'thumbnail',
            state: thumbnail,
            setState: setThumbnail,
            label: 'Thumbnail',
            type: 'file',
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1024x1600',
            previewWidth: '350px'
          },
          {
            key: 'bannerImage',
            state: bannerImage,
            setState: setBannerImage,
            label: 'Banner Image',
            type: 'file',
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1200x720',
            previewWidth: '400px'
          },
          {
            key: 'gallery',
            state: gallery,
            setState: setGallery,
            label: 'Gallery',
            type: 'file',
            isMultiple: true,
            previewType: 'image-list'
          },
          {
            key: 'publishedDate',
            state: publishedDate,
            setState: setPublishedDate,
            label: 'Published Date',
            type: 'date'
          },
          {
            key: 'isHighlight',
            state: isHighlight,
            setState: setIsHighlight,
            label: 'Pin highlight',
            type: 'check'
          },
          {
            key: 'status',
            state: status,
            setState: setStatus,
            label: 'Status',
            type: 'select',
            valuesForSelect: statusOptions
          },
          {
            key: 'order',
            state: order,
            setState: setOrder,
            label: 'Order',
            type: 'number'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
