import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Icon, Modal, Button, Select } from 'antd';

import Styled from './styled';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import { showModalWithData, hideModal } from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;
const { Option } = Select;

const path = 'form-submit';

function Index() {
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState();
  const [dataForModal, setDataForModal] = useState();
  const [modalVisible, setModalVisible] = useState();
  const [projectBudgetOptions, setProjectBudgetOptions] = useState();
  const [projectRoomTypeOptions, setProjectRoomTypeOptions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchOptions(url, setOptions) {
      const result = await sendGet(url);
      const data = _.get(result, 'data.data');
      const options = [];
      _.map(data, (d, index) => {
        const titleEn = _.get(d, 'title.en');
        const _id = _.get(d, '_id');
        options.push({
          value: _id,
          title: titleEn
        });
      });
      setOptions(options);
    }
    fetchOptions(
      `${process.env.REACT_APP_API_HOST}/project/budget/list`,
      setProjectBudgetOptions
    );
    fetchOptions(
      `${process.env.REACT_APP_API_HOST}/project/room-type/list`,
      setProjectRoomTypeOptions
    );
    async function fetchProjectList(url) {
      const result = await sendGet(url);
      const resultData = _.get(result, 'data.data');
      setProjects(resultData);
      // setProjectFilter(_.get(resultData, '0._id'));
    }
    fetchProjectList(`${process.env.REACT_APP_API_HOST}/project/list`);
    async function fetchList(url) {
      const result = await sendGet(url);
      const tmpData = _.get(result, 'data.data');
      const data = _.filter(tmpData, t => _.get(t, 'page') === 'project');
      const newData = _.map(data, d => {
        d.createdDate = moment(_.get(d, 'createdAt')).format('YYYY-MM-DD');
        d.createdTime = moment(_.get(d, 'createdAt')).format('HH:ss');
        return d;
      });
      setFullData(newData);
      setData(newData);
      setIsLoading(false);
    }
    fetchList(`${process.env.REACT_APP_API_HOST}/${path}/list`);
  }, []);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstname',
      sorter: (a, b) =>
        _.get(a, 'firstname').localeCompare(_.get(b, 'firstname'))
    },
    {
      title: 'Telephone',
      dataIndex: 'telephone',
      sorter: (a, b) =>
        _.get(a, 'telephone').localeCompare(_.get(b, 'telephone'))
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => _.get(a, 'email').localeCompare(_.get(b, 'email'))
    },
    {
      title: 'Project Name',
      dataIndex: 'projectname',
      sorter: (a, b) =>
        _.get(a, 'projectname').localeCompare(_.get(b, 'projectname'))
    },
    {
      title: 'Room Type',
      dataIndex: 'roomtype',
      sorter: (a, b) =>
        _.get(a, 'roomtype').localeCompare(_.get(b, 'roomtype')),
      render: (text, record) => {
        console.log('projectRoomTypeOptions', projectRoomTypeOptions);
        return _.get(
          _.find(projectRoomTypeOptions, p => {
            return _.get(p, 'value') === text;
          }),
          'title',
          text
        );
      }
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      sorter: (a, b) => _.get(a, 'budget').localeCompare(_.get(b, 'budget')),
      render: (text, record) => {
        console.log('projectBudgetOptions', projectBudgetOptions);
        return _.get(
          _.find(projectBudgetOptions, p => {
            return _.get(p, 'value') === text;
          }),
          'title',
          text
        );
      }
    },
    {
      title: 'Source',
      dataIndex: 'utm_source',
      sorter: (a, b) =>
        _.get(a, 'utm_source').localeCompare(_.get(b, 'utm_source'))
    },
    {
      title: 'Medium',
      dataIndex: 'utm_medium',
      sorter: (a, b) =>
        _.get(a, 'utm_medium').localeCompare(_.get(b, 'utm_medium'))
    },
    {
      title: 'Campaign',
      dataIndex: 'utm_campaign',
      sorter: (a, b) =>
        _.get(a, 'utm_campaign').localeCompare(_.get(b, 'utm_campaign'))
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return moment(text).format('YYYY-MM-DD');
      },
      sorter: (a, b) => {
        const ca = moment(_.get(a, 'createdAt')).format('YYYY-MM-DD');
        const cb = moment(_.get(b, 'createdAt')).format('YYYY-MM-DD');
        return ca.localeCompare(cb);
      }
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return moment(text).format('HH:ss');
      },
      sorter: (a, b) => {
        const ca = moment(_.get(a, 'createdAt')).format('HH:ss');
        const cb = moment(_.get(b, 'createdAt')).format('HH:ss');
        return ca.localeCompare(cb);
      }
    },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/project/${path}/edit/${_.get(record, '_id')}`} />
          <Icon
            type='zoom-in'
            style={{ color: '#1854b5', marginRight: '20px' }}
            onClick={() =>
              showModalWithData(record, setDataForModal, setModalVisible)
            }
          />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_.get(record, '_id')}
            data={data}
            setData={setData}
          />
        </div>
      )
    }
  ];
  const handleProjectFilterChange = e => {
    setProjectFilter(e);
    console.log('handleProjectFilterChange', e);
    console.log('data', data);
    setData(
      _.filter(fullData, d => {
        return _.get(d, 'projectid') === e;
      })
    );
  };
  return (
    <Styled>
      <div className='select-container'>
        <b className='select-label'>Select Project</b>
        <Select
          value={projectFilter}
          onChange={e => handleProjectFilterChange(e)}
          className='select-select'
        >
          {_.map(projects, option => {
            return (
              <Option
                key={_.get(option, 'name.en')}
                value={_.get(option, '_id')}
              >
                {_.get(option, 'name.en')}
              </Option>
            );
          })}
        </Select>
      </div>
      {projectBudgetOptions && projectRoomTypeOptions && (
        <CustomTable
          title='Register List'
          searchKeys={[
            '_id',
            'projectname',
            'budget',
            'roomtype',
            'firstname',
            'lastname',
            'telephone',
            'email',
            'createdAt',
            'utm_source',
            'utm_medium',
            'utm_campaign'
          ]}
          fullData={fullData}
          path={path}
          data={data}
          setData={setData}
          columns={columns}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          actionComponents={[
            {
              type: 'dateRange',
              inputOption: {
                filteredField: 'createdAt',
                fullState: fullData,
                setState: setData,
                hideLabel: true,
                hideContainer: true
              }
            },
            {
              type: 'excel',
              fileName: 'Project',
              columns: [
                {
                  title: 'Name',
                  value: 'firstname',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Telephone',
                  value: 'telephone',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Email',
                  value: 'email',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Project Name',
                  value: 'projectname',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Budget',
                  value: 'budget',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Room Type',
                  value: 'roomtype',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Source',
                  value: 'utm_source',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Medium',
                  value: 'utm_medium',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Campaign',
                  value: 'utm_campaign',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Date Created',
                  value: 'createdDate',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                },
                {
                  title: 'Time Created',
                  value: 'createdTime',
                  style: {
                    fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                  }
                }
              ],
              valueKeys: [
                'firstname',
                'telephone',
                'email',
                'projectname',
                'budget',
                'roomtype',
                'utm_source',
                'utm_medium',
                'utm_campaign',
                'createdDate',
                'createdTime'
              ],
              data: data,
              sheetName: 'Form Submit'
            }
          ]}
        />
      )}
      <Modal
        title='Full Detail'
        visible={modalVisible}
        onCancel={() => hideModal(setModalVisible)}
        footer={[
          <Button key='back' onClick={() => hideModal(setModalVisible)}>
            Return
          </Button>
        ]}
      >
        <p>
          <b>Name: </b>
          {_.get(dataForModal, 'firstname')}
        </p>
        <p>
          <b>Email: </b>
          {_.get(dataForModal, 'email')}
        </p>
        <p>
          <b>Telephone: </b>
          {_.get(dataForModal, 'telephone')}
        </p>
        <p>
          <b>Project Name: </b>
          {_.get(dataForModal, 'projectname')}
        </p>
        <p>
          <b>Budget: </b>
          {_.get(dataForModal, 'budget')}
        </p>
        <p>
          <b>Room Type: </b>
          {_.get(dataForModal, 'roomtype')}
        </p>
        <p>
          <b>Source: </b>
          {_.get(dataForModal, 'utm_source')}
        </p>
        <p>
          <b>Medium: </b>
          {_.get(dataForModal, 'utm_medium')}
        </p>
        <p>
          <b>Campaign: </b>
          {_.get(dataForModal, 'utm_campaign')}
        </p>
        <p>
          <b>Date: </b>
          {moment(_.get(dataForModal, 'createdAt', new Date())).format(
            'YYYY-MM-DD'
          )}
        </p>
        <p>
          <b>Time: </b>
          {moment(_.get(dataForModal, 'createdAt', new Date())).format('HH:ss')}
        </p>
      </Modal>
    </Styled>
  );
}

export default Index;