import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Styled from './styled';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import { projectSectionNames } from '../../../utils/variables';
import {
  appendSectionSwitchInputs,
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;

const rootPath = 'project';
const dataKey = 'banner.banners';

function Index(props) {
  const _id = _.get(props, 'match.params._id');
  const path = `project/edit/${_id}/banner`;
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSections, setShowSections] = useState();
  const tmpInputFields = [];
  const [forceUpdate, setForceUpdate] = useState();
  appendSectionSwitchInputs(
    'showSections',
    tmpInputFields,
    showSections,
    setShowSections,
    setForceUpdate
  );
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: `/project/edit/${_id}` }
  ];
  appendSectionSelectOptions(
    sectionSelectOptions,
    showSections,
    `/project/edit/${_id}`
  );
  sortArrayWithKey(sectionSelectOptions, 'order');
  useEffect(() => {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      sortArrayWithKey(data, 'order');
      setShowSections(_.get(result, 'data.data.showSections'));
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
  }, []);
  const columns = [
    { title: 'Resource Name', dataIndex: 'resourceName' },
    { title: 'Resource Type', dataIndex: 'resourceType' },
    { title: 'Status', dataIndex: 'status' },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/edit/${_.get(record, '_id')}`} />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_id}
            data={data}
            setData={setData}
            childId={_.get(record, '_id')}
            dataKey={dataKey}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        title='Banner List'
        sectionSelectInfo={{
          label: 'Sections',
          defaultValue: `/${path}`,
          sections: sectionSelectOptions
        }}
        searchKeys={['_id', 'resourceName', 'resourceType', 'status']}
        rootId={_id}
        childKey={dataKey}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/new` }
        ]}
      />
    </Styled>
  );
}

export default Index;
