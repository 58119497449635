import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import { sortArrayWithKey } from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;

const rootPath = 'contact-us';
const path = 'contact-us/contact';
const outerDataKey = 'contact';
const dataKey = 'contact.subjects';
const subjectPath = 'contact-us/subject';

function Index() {
  const action = 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const [companyName, setCompanyName] = useState();
  const [welcome, setWelcome] = useState();
  const [location, setLocation] = useState();
  const [contactInfos, setContactInfos] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState();
  useEffect(() => {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      set_id(_.get(result, 'data.data._id'));
      const outerData =
        outerDataKey !== ''
          ? _.get(result, `data.data.${outerDataKey}`, [])
          : '';
      setCompanyName(_.get(outerData, 'companyName'));
      setWelcome(_.get(outerData, 'welcome'));
      setLocation(_.get(outerData, 'location'));
      setContactInfos(_.get(outerData, 'contactInfos'));
      setBackgroundImage(_.get(outerData, 'backgroundImage'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      sortArrayWithKey(data, 'order');
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
  }, []);
  const columns = [
    { title: 'Title', dataIndex: 'title.en' },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/edit/${_.get(record, '_id')}`} />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${subjectPath}/delete`}
            _id={_id}
            data={data}
            setData={setData}
            childId={_.get(record, '_id')}
            dataKey={dataKey}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        autoHeight={true}
        title='Subject List'
        searchKeys={['_id', 'title.en', 'status']}
        rootId={_id}
        childKey={dataKey}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/new` }
        ]}
      />
      <CustomForm
        autoHeight={true}
        action={action}
        title={`${action} Contact Section`}
        inputFields={[
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x420',
            previewWidth: '400px'
          },
          {
            key: 'companyName',
            state: companyName,
            setState: setCompanyName,
            multiLang: true,
            label: 'Company Name',
            type: 'text'
          },
          {
            key: 'welcome',
            state: welcome,
            setState: setWelcome,
            multiLang: true,
            label: 'Welcome Text',
            type: 'text'
          },
          {
            key: 'location',
            state: location,
            setState: setLocation,
            multiLang: true,
            label: 'Location Text',
            type: 'text'
          },
          {
            key: 'contactInfos',
            state: contactInfos,
            setState: setContactInfos,
            label: 'Contact Information',
            multiLang: true,
            type: 'dynamic',
            setForceUpdate: setForceUpdate,
            hideDescription: true
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${rootPath}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: outerDataKey,
            updateOuter: true
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default Index;
