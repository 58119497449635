import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { sendGet } from '../../../utils/requestHandle';

const path = 'form-submit';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [telephone, setTelephone] = useState();
  const [email, setEmail] = useState();
  useEffect(() => {
    setIsLoading(true);
    if (action === 'Edit') {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        setFirstname(_.get(data, 'firstname'));
        setLastname(_.get(data, 'lastname'));
        setTelephone(_.get(data, 'telephone'));
        setEmail(_.get(data, 'email'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/view/${_id}`);
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Form Submit`}
        inputFields={[
          {
            key: 'firstname',
            state: firstname,
            setState: setFirstname,
            label: 'First Name',
            type: 'text'
          },
          {
            key: 'lastname',
            state: lastname,
            setState: setLastname,
            label: 'Last Name',
            type: 'text'
          },
          {
            key: 'telephone',
            state: telephone,
            setState: setTelephone,
            label: 'Telephone',
            type: 'text'
          },
          {
            key: 'email',
            state: email,
            setState: setEmail,
            label: 'Email',
            type: 'text'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            redirectPath: `/contact-us/${path}`,
            setIsLoading: setIsLoading
          },
          { type: 'back', title: 'Back', path: `/contact-us/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
