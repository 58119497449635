import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import {
  backgroundTypeOptions,
  statusOptions,
  projectSectionNames,
  typeOptions
} from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import {
  fetchNextOrderOfSubCollection,
  getNext,
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../../utils/global';

const rootPath = 'project';
const dataKey = 'concept.concepts';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const childId = _.get(props, 'match.params.child_id');
  const projectEditPath = `/project/edit/${_id}`;
  const path = `project/edit/${_id}/concept`;
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('Project');
  const [backgroundType, setBackgroundType] = useState('Image');
  const [background, setBackground] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [title, setTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [description, setDescription] = useState();
  const [editorDescription, setEditorDescription] = useState();
  const [textIcons, setTextIcons] = useState();
  const [image, setImage] = useState();
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  const [forceUpdate, setForceUpdate] = useState();
  const [showSections, setShowSections] = useState();
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: projectEditPath }
  ];
  appendSectionSelectOptions(
    sectionSelectOptions,
    showSections,
    projectEditPath
  );
  sortArrayWithKey(sectionSelectOptions, 'order');
  let inputFields = [
    {
      key: 'sectionSelect',
      label: 'Sections',
      type: 'sectionSelect',
      defaultValue: `/${path}`,
      sections: sectionSelectOptions
    },
    {
      key: 'type',
      state: type,
      setState: setType,
      label: 'Type',
      type: 'select',
      valuesForSelect: typeOptions
    },
    {
      key: 'backgroundType',
      state: backgroundType,
      setState: setBackgroundType,
      label: 'Background Type',
      type: 'select',
      valuesForSelect: backgroundTypeOptions
    },
    {
      key: 'background',
      state: background,
      setState: setBackground,
      label: 'Background',
      type: 'file',
      previewType: 'image',
      maxFileSize: 3,
      resolution: '1920x860',
      previewWidth: '400px'
    },
    {
      key: 'backgroundColor',
      state: backgroundColor,
      setState: setBackgroundColor,
      label: 'Background Color',
      type: 'colorPicker'
    },
    {
      key: 'title',
      state: title,
      setState: setTitle,
      label: 'Title',
      multiLang: true,
      type: 'text'
    },
    {
      key: 'image',
      state: image,
      setState: setImage,
      label: 'Image',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'image',
      maxFileSize: 5,
      resolution: '850x650',
      previewWidth: '300px'
    },
    {
      key: 'status',
      state: status,
      setState: setStatus,
      label: 'Status',
      type: 'select',
      valuesForSelect: statusOptions
    },
    {
      key: 'order',
      state: order,
      setState: setOrder,
      label: 'Order',
      type: 'number'
    }
  ];
  switch (type) {
    case 'Facility':
      inputFields = inputFields.concat([
        {
          key: 'textIcons',
          state: textIcons,
          setState: setTextIcons,
          label: 'Text Icon',
          multiLang: true,
          type: 'dynamic',
          setForceUpdate: setForceUpdate
        }
      ]);
      break;
    case 'Detail':
      inputFields = inputFields.concat([
        {
          key: 'editorDescription',
          state: editorDescription,
          setState: setEditorDescription,
          multiLang: true,
          label: 'Editor Description',
          placeholder: 'Enter Editor Description',
          type: 'editor',
          action: action,
          height: '350px'
        }
      ]);
      break;
    default:
      inputFields = inputFields.concat([
        {
          key: 'subTitle',
          state: subTitle,
          setState: setSubTitle,
          label: 'Sub Title',
          multiLang: true,
          type: 'text'
        },
        {
          key: 'description',
          state: description,
          setState: setDescription,
          label: 'Description',
          multiLang: true,
          type: 'textarea'
        }
      ]);
      break;
  }
  console.log('inputFields', inputFields);
  useEffect(() => {
    setIsLoading(true);
    async function fetchDoc(url) {
      const result = await sendGet(url);
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      const nextOrder = getNext('order', data);
      const childData = _.find(data, d => _.get(d, '_id') === childId);
      setShowSections(_.get(result, 'data.data.showSections'));
      setType(_.get(childData, 'type', 'Project'));
      setBackgroundType(_.get(childData, 'backgroundType', 'Image'));
      setBackground(_.get(childData, 'background'));
      setBackgroundColor(_.get(childData, 'backgroundColor'));
      setTitle(_.get(childData, 'title'));
      setSubTitle(_.get(childData, 'subTitle'));
      setDescription(_.get(childData, 'description'));
      setEditorDescription(_.get(childData, 'editorDescription'));
      setTextIcons(_.get(childData, 'textIcons'));
      setImage(_.get(childData, 'image'));
      setStatus(_.get(childData, 'status', 'Show'));
      setOrder(_.get(childData, 'order', nextOrder));
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Concept`}
        inputFields={inputFields}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: dataKey,
            childId: childId
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
