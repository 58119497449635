import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import _ from 'lodash';

import SideMenu from './components/SideMenu';
import UserLogin from './pages/User/Login';
import routes from './routes';
import './App.css';

const renderRouter = () => {
  const pages = _.map(routes, (item, i) => {
    return item;
  });
  return pages;
};

function App(props) {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin'));
  return (
    <Router basename='/'>
      <div className='app-container'>
        <SideMenu isLogin={isLogin}></SideMenu>
        <div className='body-container'>
          {isLogin === 'true' ? (
            <div>{renderRouter()}</div>
          ) : (
            <div>
              <Route
                key={'/login'}
                path={'/login'}
                exact={true}
                component={UserLogin}
              />
              <Redirect to='/login' />
            </div>
          )}
        </div>
        <div
          id='modal-content'
          onClick={e => (e.target.style.display = 'none')}
        ></div>
      </div>
    </Router>
  );
}

export default App;
