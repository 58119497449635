import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { getNext } from '../../../utils/global';
import { sendGet } from '../../../utils/requestHandle';
import { statusOptions } from '../../../utils/variables';

const rootPath = 'contact-us';
const path = 'contact-us/contact';
const dataKey = 'contact.subjects';
const subjectPath = 'contact-us/subject';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const childId = _.get(props, 'match.params._id');
  const [_id, set_id] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState();
  const [to, setTo] = useState();
  const [cc, setCc] = useState();
  const [bcc, setBcc] = useState();
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  useEffect(() => {
    // if (action === 'Edit') {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      set_id(_.get(result, 'data.data._id'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      const nextOrder = getNext('order', data);
      const childData = _.find(data, d => _.get(d, '_id') === childId);
      setTitle(_.get(childData, 'title'));
      setTo(_.get(childData, 'to'));
      setCc(_.get(childData, 'cc'));
      setBcc(_.get(childData, 'bcc'));
      setStatus(_.get(childData, 'status', 'Show'));
      setOrder(_.get(childData, 'order', nextOrder));
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
    // }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Subject`}
        inputFields={[
          {
            key: 'title',
            state: title,
            setState: setTitle,
            multiLang: true,
            label: 'Title',
            type: 'text'
          },
          {
            key: 'to',
            state: to,
            setState: setTo,
            label: 'Mail To',
            type: 'text'
          },
          {
            key: 'cc',
            state: cc,
            setState: setCc,
            label: 'Mail Cc',
            type: 'text'
          },
          {
            key: 'bcc',
            state: bcc,
            setState: setBcc,
            label: 'Mail Bcc',
            type: 'text'
          },
          {
            key: 'status',
            state: status,
            setState: setStatus,
            label: 'Status',
            type: 'select',
            valuesForSelect: statusOptions
          },
          {
            key: 'order',
            state: order,
            setState: setOrder,
            label: 'Order',
            type: 'number'
          }
        ]}
        actionComponents={[
          { type: 'back', title: 'Back', path: `/${path}` },
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${subjectPath}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: dataKey,
            childId: childId
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
