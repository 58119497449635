import axios from 'axios';
import _ from 'lodash';

export const sendGet = async url => {
  return await axios(url)
    .then(response => {
      console.log('sendGet response', _.get(response, 'status'), response);
      return response;
    })
    .catch(err => {
      console.log('sendGet error', err);
      return false;
    });
};

export const sendPost = async (url, data, contentType = 'application/json') => {
  return await axios({
    method: 'POST',
    url: url,
    data: data,
    config: { headers: { 'Content-Type': contentType } }
  })
    .then(response => {
      console.log('sendPost response', _.get(response, 'status'), response);
      return response;
    })
    .catch(err => {
      console.log('sendPost error', err);
      return false;
    });
};

export const sendPatch = async (url, data) => {
  return await axios({
    method: 'PATCH',
    url: url,
    data: data,
    config: { headers: { 'Content-Type': 'application/json' } }
  })
    .then(response => {
      console.log('sendPatch response', _.get(response, 'status'), response);
      return response;
    })
    .catch(err => {
      console.log('sendPatch error', err);
      return false;
    });
};

export const sendDelete = async (url, _id, childId, dataKey) => {
  return await axios
    .delete(url, {
      data: { _id: _id, childId: childId, dataKey: dataKey }
    })
    .then(response => {
      console.log('sendDelete response', _.get(response, 'status'), response);
      return response;
    })
    .catch(err => {
      console.log('sendDelete error', err);
      return false;
    });
};
