import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import { statusOptions, projectSectionNames } from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import {
  fetchNextOrderOfSubCollection,
  getNext,
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../../utils/global';

const rootPath = 'project';
const dataKey = 'room-type.room-types';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const childId = _.get(props, 'match.params.child_id');
  const projectEditPath = `/project/edit/${_id}`;
  const path = `project/edit/${_id}/room-type`;
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [roomType, setRoomType] = useState();
  const [roomTypeOptions, setRoomTypeOptions] = useState();
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  const [forceUpdate, setForceUpdate] = useState();
  const [showSections, setShowSections] = useState();
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: projectEditPath }
  ];
  appendSectionSelectOptions(
    sectionSelectOptions,
    showSections,
    projectEditPath
  );
  sortArrayWithKey(sectionSelectOptions, 'order');
  const inputFields = [
    {
      key: 'sectionSelect',
      label: 'Sections',
      type: 'sectionSelect',
      defaultValue: `/${path}`,
      sections: sectionSelectOptions
    },
    {
      key: 'title',
      state: title,
      setState: setTitle,
      label: 'Title',
      multiLang: true,
      type: 'text'
    },
    {
      key: 'image',
      state: image,
      setState: setImage,
      label: 'Image',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'image',
      maxFileSize: 5,
      resolution: '1920x1080',
      previewWidth: '250px'
    },
    {
      key: 'roomType',
      state: roomType,
      setState: setRoomType,
      label: 'Room Type',
      type: 'select',
      valuesForSelect: roomTypeOptions
    },
    {
      key: 'status',
      state: status,
      setState: setStatus,
      label: 'Status',
      type: 'select',
      valuesForSelect: statusOptions
    },
    {
      key: 'order',
      state: order,
      setState: setOrder,
      label: 'Order',
      type: 'number'
    }
  ];
  useEffect(() => {
    setIsLoading(true);
    async function fetchOptions(url, setOptions, setValue) {
      const result = await sendGet(url);
      const data = _.get(result, 'data.data');
      const options = [];
      _.map(data, (d, index) => {
        const titleEn = _.get(d, 'title.en');
        if (index === 0) {
          setValue(titleEn);
        }
        options.push({
          title: titleEn,
          value: titleEn
        });
      });
      setOptions(options);
    }
    fetchOptions(
      `${process.env.REACT_APP_API_HOST}/project/room-type/list`,
      setRoomTypeOptions,
      setRoomType
    );
    async function fetchDoc(url) {
      const result = await sendGet(url);
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      const nextOrder = getNext('order', data);
      const childData = _.find(data, d => _.get(d, '_id') === childId);
      setShowSections(_.get(result, 'data.data.showSections'));
      setTitle(_.get(childData, 'title'));
      setRoomType(_.get(childData, 'roomType', 'Studio'));
      setImage(_.get(childData, 'image'));
      setStatus(_.get(childData, 'status', 'Show'));
      setOrder(_.get(childData, 'order', nextOrder));
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Room Type`}
        inputFields={inputFields}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: dataKey,
            childId: childId
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
