import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../components/CustomForm';
import { statusOptions, projectSectionNames } from '../../utils/variables';
import { sendGet } from '../../utils/requestHandle';
import {
  fetchNextOrderOfCollection,
  appendSectionSwitchInputs,
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../utils/global';

const path = 'project';
const realValueKeys = ['_id', 'title'];

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();
  const [slug, setSlug] = useState();
  const [tagLine, setTagLine] = useState();
  const [description, setDescription] = useState();
  const [highlightInfos, setHighlightInfos] = useState();
  const [logo, setLogo] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [wideThumbnail, setWideThumbnail] = useState();
  const [thankYouImg, setThankYouImg] = useState();
  const [linkOut, setLinkOut] = useState();
  const [projectStatus, setProjectStatus] = useState();
  const [projectStatusOptions, setProjectStatusOptions] = useState();
  const [projectStatusFullOptions, setProjectStatusFullOptions] = useState();
  const [projectStatusRealValue, setProjectStatusRealValue] = useState();
  const [projectType, setProjectType] = useState();
  const [projectTypeOptions, setProjectTypeOptions] = useState();
  const [projectTypeFullOptions, setProjectTypeFullOptions] = useState();
  const [projectTypeRealValue, setProjectTypeRealValue] = useState();
  const [projectLocation, setProjectLocation] = useState();
  const [projectLocationOptions, setProjectLocationOptions] = useState();
  const [
    projectLocationFullOptions,
    setProjectLocationFullOptions
  ] = useState();
  const [projectLocationRealValue, setProjectLocationRealValue] = useState();
  const [projectPrice, setProjectPrice] = useState();
  const [mainColor, setMainColor] = useState();
  const [buttonTextColor, setButtonTextColor] = useState();
  const [page, setPage] = useState('project');
  const [to, setTo] = useState();
  const [cc, setCc] = useState();
  const [bcc, setBcc] = useState();
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  const [forceUpdate, setForceUpdate] = useState();
  const [showSections, setShowSections] = useState();
  const tmpInputFields = [];
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: `/project/edit/${_id}` }
  ];
  if (action !== 'New') {
    appendSectionSwitchInputs(
      'showSections',
      tmpInputFields,
      showSections,
      setShowSections,
      setForceUpdate
    );
    appendSectionSelectOptions(
      sectionSelectOptions,
      showSections,
      `/project/edit/${_id}`
    );
    sortArrayWithKey(sectionSelectOptions, 'order');
  }
  const inputFields = tmpInputFields.concat([
    {
      key: 'sectionSelect',
      label: 'Sections',
      type: 'sectionSelect',
      defaultValue: `/${path}/edit/${_id}`,
      sections: sectionSelectOptions,
      hide: action === 'New'
    },
    {
      key: 'name',
      state: name,
      setState: setName,
      multiLang: true,
      label: 'Name',
      type: 'text',
      validation: [{ type: 'required' }]
    },
    {
      key: 'slug',
      state: slug,
      setState: setSlug,
      label: 'Slug',
      type: 'text',
      validation: [{ type: 'required' }]
    },
    {
      key: 'tagLine',
      state: tagLine,
      setState: setTagLine,
      multiLang: true,
      label: 'Tag Line',
      type: 'textarea'
    },
    {
      key: 'description',
      state: description,
      setState: setDescription,
      multiLang: true,
      label: 'Description',
      placeholder: 'Enter Description',
      action: action,
      type: 'editor'
    },
    {
      key: 'linkOut',
      state: linkOut,
      setState: setLinkOut,
      label: 'Link Out',
      type: 'text'
    },
    {
      key: 'highlightInfos',
      state: highlightInfos,
      setState: setHighlightInfos,
      label: 'Highlight Information',
      multiLang: true,
      type: 'dynamic',
      setForceUpdate: setForceUpdate,
      hideDescription: true,
      resolution: '60x60'
    },
    {
      key: 'logo',
      state: logo,
      setState: setLogo,
      label: 'Logo',
      type: 'file',
      previewType: 'image',
      maxFileSize: 1,
      resolution: '*x110',
      previewWidth: '100px'
    },
    {
      key: 'thumbnail',
      state: thumbnail,
      setState: setThumbnail,
      label: 'Thumbnail',
      type: 'file',
      previewType: 'image',
      maxFileSize: 3,
      resolution: '440x640',
      previewWidth: '200px'
    },
    {
      key: 'wideThumbnail',
      state: wideThumbnail,
      setState: setWideThumbnail,
      label: 'Wide Thumbnail',
      type: 'file',
      previewType: 'image',
      maxFileSize: 3,
      resolution: '1500x560',
      previewWidth: '300px'
    },
    {
      key: 'thankYouImg',
      state: thankYouImg,
      setState: setThankYouImg,
      label: 'EDM Thankyou',
      type: 'file',
      previewType: 'image',
      maxFileSize: 3,
      resolution: '450x600',
      previewWidth: '300px'
    },
    {
      key: 'projectStatus',
      state: projectStatus,
      setState: setProjectStatus,
      label: 'Project Status',
      type: 'select',
      valuesForSelect: projectStatusOptions,
      fullOptions: projectStatusFullOptions,
      realValue: projectStatusRealValue,
      setRealValue: setProjectStatusRealValue,
      realValueKeys: realValueKeys
    },
    {
      key: 'projectType',
      state: projectType,
      setState: setProjectType,
      label: 'Project Type',
      type: 'select',
      valuesForSelect: projectTypeOptions,
      fullOptions: projectTypeFullOptions,
      realValue: projectTypeRealValue,
      setRealValue: setProjectTypeRealValue,
      realValueKeys: realValueKeys
    },
    {
      key: 'projectLocation',
      state: projectLocation,
      setState: setProjectLocation,
      label: 'Project Location',
      type: 'select',
      valuesForSelect: projectLocationOptions,
      fullOptions: projectLocationFullOptions,
      realValue: projectLocationRealValue,
      setRealValue: setProjectLocationRealValue,
      realValueKeys: realValueKeys
    },
    {
      key: 'projectPrice',
      state: projectPrice,
      setState: setProjectPrice,
      label: 'Project Price (MB)',
      type: 'number'
    },
    {
      key: 'mainColor',
      state: mainColor,
      setState: setMainColor,
      label: 'Main Color',
      type: 'colorPicker'
    },
    {
      key: 'buttonTextColor',
      state: buttonTextColor,
      setState: setButtonTextColor,
      label: 'Button Text Color',
      type: 'colorPicker'
    },
    {
      key: 'to',
      state: to,
      setState: setTo,
      label: 'Mail To',
      type: 'text'
    },
    {
      key: 'cc',
      state: cc,
      setState: setCc,
      label: 'Mail Cc',
      type: 'text'
    },
    {
      key: 'bcc',
      state: bcc,
      setState: setBcc,
      label: 'Mail Bcc',
      type: 'text'
    },
    {
      key: 'status',
      state: status,
      setState: setStatus,
      label: 'Status',
      type: 'select',
      valuesForSelect: statusOptions
    },
    {
      key: 'order',
      state: order,
      setState: setOrder,
      label: 'Order',
      type: 'number'
    }
  ]);
  useEffect(() => {
    setIsLoading(true);
    async function fetchOptions(
      url,
      setOptions,
      setValue,
      setFullOptions,
      setRealValue,
      realValueKeys
    ) {
      const result = await sendGet(url);
      const data = _.get(result, 'data.data');
      setFullOptions(data);
      const options = [];
      _.map(data, (d, index) => {
        const titleEn = _.get(d, 'title.en');
        const optionId = _.get(d, '_id');
        if (index === 0) {
          setValue(optionId);
          const realValueObject = {};
          _.map(realValueKeys, r => {
            realValueObject[r] = _.get(d, r);
          });
          setRealValue(realValueObject);
        }
        options.push({
          title: titleEn,
          value: optionId
        });
      });
      setOptions(options);
    }
    fetchOptions(
      `${process.env.REACT_APP_API_HOST}/project/status/list`,
      setProjectStatusOptions,
      setProjectStatus,
      setProjectStatusFullOptions,
      setProjectStatusRealValue,
      realValueKeys
    );
    fetchOptions(
      `${process.env.REACT_APP_API_HOST}/project/type/list`,
      setProjectTypeOptions,
      setProjectType,
      setProjectTypeFullOptions,
      setProjectTypeRealValue,
      realValueKeys
    );
    fetchOptions(
      `${process.env.REACT_APP_API_HOST}/project/location/list`,
      setProjectLocationOptions,
      setProjectLocation,
      setProjectLocationFullOptions,
      setProjectLocationRealValue,
      realValueKeys
    );
    if (action === 'Edit') {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        setShowSections(_.get(data, 'showSections'));
        setName(_.get(data, 'name'));
        setSlug(_.get(data, 'slug'));
        setTagLine(_.get(data, 'tagLine'));
        setLinkOut(_.get(data, 'linkOut'));
        setDescription(_.get(data, 'description'));
        setLogo(_.get(data, 'logo'));
        setThumbnail(_.get(data, 'thumbnail'));
        setWideThumbnail(_.get(data, 'wideThumbnail'));
        setThankYouImg(_.get(data, 'thankYouImg'));
        setProjectStatus(_.get(data, 'projectStatus._id'));
        setProjectStatusRealValue(_.get(data, 'projectStatus'));
        setProjectType(_.get(data, 'projectType._id'));
        setProjectTypeRealValue(_.get(data, 'projectType'));
        setProjectLocation(_.get(data, 'projectLocation._id'));
        setProjectLocationRealValue(_.get(data, 'projectLocation'));
        setProjectPrice(_.get(data, 'projectPrice'));
        setHighlightInfos(_.get(data, 'highlightInfos'));
        setMainColor(_.get(data, 'mainColor'));
        setButtonTextColor(_.get(data, 'buttonTextColor'));
        setTo(_.get(data, 'to'));
        setCc(_.get(data, 'cc'));
        setBcc(_.get(data, 'bcc'));
        setStatus(_.get(data, 'status', 'Show'));
        setOrder(_.get(data, 'order', 1));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/view/${_id}`);
    } else {
      fetchNextOrderOfCollection(
        `${process.env.REACT_APP_API_HOST}/${path}/max/order`,
        setOrder,
        setIsLoading
      );
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Project`}
        inputFields={inputFields}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
