import React, { useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Card, Tabs, Spin } from 'antd';

import Styled from './styled';
import CustomButton from '../CustomButton';
import { languages } from '../../utils/variables';
import { TextInput, PasswordInput, NumberInput, SwitchInput, TextAreaInput, SelectInput, CheckInput, DateInput, ColorPickerInput, SectionSelectInput, TransferInput, FileInput, EditorInput, DynamicInput } from '../CustomInput';

const { SubmitButton, BackButton } = CustomButton;
const { TabPane } = Tabs;

const handleTabChange = (currentLang, setCurrentLang) => {
  setCurrentLang(currentLang);
};

export default withRouter(function CustomForm(props) {
  /*
  props: 
    action(string),
    title(string),
    inputFields(objectArray
      eg. [
          { key: 'title', state: title, setState: setTitle, multiLang: true, label: 'Title', type: 'text', validation: [{ type: 'required' }] },
          { key: 'thumbnail', state: thumbnail, setState: setThumbnail, label: 'Thumbnail', type: 'file' },
        ]
    ), 
    actionComponents([{ type(string), title(string), path(string), url(string) }]),
    isLoading(boolean),
  */
  const { action, title, inputFields, actionComponents, isLoading, history, autoHeight } = props;
  const submitBtnAction = _.find(actionComponents, (a) => _.get(a, 'type') === 'submit');
  const backBtnAction = _.find(actionComponents, (a) => _.get(a, 'type') === 'back');
  const [currentLang, setCurrentLang] =  useState(_.get(languages, '0.key'));
  const topSwitchInputs = _.filter(inputFields, (input) => {
    return _.get(input, 'type') === 'switch' && _.get(input, 'showOnTop') === true;
  });
  const sectionSelectInput = _.find(inputFields, (input) => _.get(input, 'type') === 'sectionSelect');
  let shouldRenderMultiLang = false;
  return (
    <Spin
      spinning={isLoading}
      size="large"
    >
      <Styled autoHeight={autoHeight}>
        {topSwitchInputs && (
          <div className="inline-container">
            {
              _.map(topSwitchInputs, (input, index) => {
                return _.get(input, 'label') !== 'Default' && (
                  <SwitchInput key={index} input={input} />
                );
              })
            }
          </div>
        )}
        {sectionSelectInput && !_.get(sectionSelectInput, 'hide') && (<SectionSelectInput key={'sectionSelect'} input={sectionSelectInput} history={history} />)}
        <Card type="inner" title={title}>
          {
            _.map(inputFields, (input, index) => {
              if (!_.get(input, 'multiLang')) {
                const key = _.get(input, 'key');
                switch (_.get(input, 'type')) {
                  case 'text':
                    return (
                      <TextInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'password':
                    return (
                      <PasswordInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'number':
                    return (
                      <NumberInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'switch':
                    return _.get(input, 'showOnTop') !== true && (
                      <SwitchInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'textarea':
                    return (
                      <TextAreaInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'select':
                    return (
                      <SelectInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'check':
                    return (
                      <CheckInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'date':
                    return (
                      <DateInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'colorPicker':
                    return (
                      <ColorPickerInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'transfer':
                    return (
                      <TransferInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'editor':
                    return (
                      <EditorInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'file':
                    return (
                      <FileInput elementKey={`${key}-${index}`} input={input} />
                    );
                  case 'dynamic':
                    return (
                      <DynamicInput elementKey={`${key}-${index}`} input={input} />
                    );
                  default:
                    break;
                }
              } else {
                shouldRenderMultiLang = true;
              }
            })
          }
          {shouldRenderMultiLang && (
            <Tabs
              hideAdd
              onChange={(e) => handleTabChange(e, setCurrentLang)}
              activeKey={currentLang}
              type="card"
            >
              {
                _.map(languages, (lang) => (
                <TabPane tab={lang.title} key={lang.key}>
                {
                  _.map(inputFields, (input, index) => {
                    if (_.get(input, 'multiLang')) {
                      const key = _.get(input, 'key');
                      switch (_.get(input, 'type')) {
                        case 'text':
                          return (
                            <TextInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'number':
                          return (
                            <NumberInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'textarea':
                          return (
                            <TextAreaInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'select':
                          return (
                            <SelectInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'check':
                          return (
                            <CheckInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'date':
                          return (
                            <DateInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'colorPicker':
                          return (
                            <ColorPickerInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'transfer':
                          return (
                            <TransferInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'editor':
                          return (
                            <EditorInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'file':
                          return (
                            <FileInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        case 'dynamic':
                          return (
                            <DynamicInput elementKey={`${key}-${currentLang}-${index}`} input={input} lang={currentLang} />
                          );
                        default:
                          break;
                      }
                    }
                  })
                }
                </TabPane>
              ))}
            </Tabs>  
          )}
          <div className="button-container">
            { backBtnAction && <BackButton path={_.get(backBtnAction, 'path')} title={_.get(backBtnAction, 'title')} /> }      
            { submitBtnAction && <SubmitButton url={_.get(submitBtnAction, 'url')} path={_.get(submitBtnAction, 'path')} action={action} inputFields={inputFields} title={_.get(submitBtnAction, 'title')} _id={_.get(submitBtnAction, '_id')} setIsLoading={_.get(submitBtnAction, 'setIsLoading')} redirectPath={_.get(submitBtnAction, 'redirectPath')} dataKey={_.get(submitBtnAction, 'dataKey')} childId={_.get(submitBtnAction, 'childId')} updateOuter={_.get(submitBtnAction, 'updateOuter')} /> }
          </div>
        </Card>
      </Styled>
    </Spin>
  );
})