import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { sendGet } from '../../../utils/requestHandle';

const path = 'form-submit';
const realValueKey = '_id';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState();
  const [telephone, setTelephone] = useState();
  const [email, setEmail] = useState();
  const [budget, setBudget] = useState();
  const [projectBudgetOptions, setProjectBudgetOptions] = useState();
  const [roomtype, setRoomtype] = useState();
  const [projectRoomTypeOptions, setProjectRoomTypeOptions] = useState();
  useEffect(() => {
    setIsLoading(true);
    if (action === 'Edit') {
      async function fetchOptions(url, setOptions, setValue) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        const options = [];
        _.map(data, (d, index) => {
          const titleEn = _.get(d, 'title.en');
          const _id = _.get(d, '_id');
          options.push({
            value: _id,
            title: titleEn
          });
        });
        setOptions(options);
      }
      fetchOptions(
        `${process.env.REACT_APP_API_HOST}/project/budget/list`,
        setProjectBudgetOptions,
        setBudget
      );
      fetchOptions(
        `${process.env.REACT_APP_API_HOST}/project/room-type/list`,
        setProjectRoomTypeOptions,
        setRoomtype
      );
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        setFirstname(_.get(data, 'firstname'));
        setTelephone(_.get(data, 'telephone'));
        setEmail(_.get(data, 'email'));
        setBudget(_.get(data, 'budget'));
        setRoomtype(_.get(data, 'roomtype'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/view/${_id}`);
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Register`}
        inputFields={[
          {
            key: 'firstname',
            state: firstname,
            setState: setFirstname,
            label: 'Name',
            type: 'text'
          },
          {
            key: 'telephone',
            state: telephone,
            setState: setTelephone,
            label: 'Telephone',
            type: 'text'
          },
          {
            key: 'email',
            state: email,
            setState: setEmail,
            label: 'Email',
            type: 'text'
          },
          {
            key: 'budget',
            state: budget,
            setState: setBudget,
            label: 'Project Budget',
            type: 'select',
            valuesForSelect: projectBudgetOptions
          },
          {
            key: 'roomtype',
            state: roomtype,
            setState: setRoomtype,
            label: 'Project Room Type',
            type: 'select',
            valuesForSelect: projectRoomTypeOptions
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            redirectPath: `/project/${path}`,
            setIsLoading: setIsLoading
          },
          { type: 'back', title: 'Back', path: `/project/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
