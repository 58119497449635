import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Styled from './styled';
import CustomTable from '../../components/CustomTable';
import CustomButton from '../../components/CustomButton';
import { sendGet } from '../../utils/requestHandle';

const { EditButton, DeleteButton } = CustomButton;

const path = 'email-receiver';

function Index() {
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchList(url) {
      const result = await sendGet(url);
      const data = _.get(result, 'data.data');
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchList(`${process.env.REACT_APP_API_HOST}/${path}/list`);
  }, []);
  const columns = [
    { title: 'Page', dataIndex: 'page' },
    { title: 'To', dataIndex: 'to' },
    { title: 'Cc', dataIndex: 'cc' },
    { title: 'Bcc', dataIndex: 'bcc' },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/edit/${_.get(record, '_id')}`} />
          {/* <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_.get(record, '_id')}
            data={data}
            setData={setData}
          /> */}
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        title='Email Receiver List'
        searchKeys={['_id', 'page', 'to', 'cc', 'bcc', 'status']}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/new` }
        ]}
      />
    </Styled>
  );
}

export default Index;
