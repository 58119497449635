import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.autoHeight ? 'auto': '100vh'};
  padding: 20px;
  overflow-y: auto;
  background-color: white;
  .page-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .sub-title-container {
      display: flex;
    }
    .left-container {
      display: flex;
      flex-direction: column;
    }
    .right-container {
      display: flex;
    }
  }
  .ant-table-row {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }
  .search {
    width: 250px;
    margin-right: 10px;
  }
`;