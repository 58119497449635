import styled from "styled-components";

export default styled.div`
  position: relative;
  display: none;
  height: 100vh;
  width: 265px;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: #3d331c;
  .logo-container {
    margin: 25px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 55%;
      height: auto;
    }
  }
  .menu-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-menu {
    background-color: #3d331c;
    width: 215px;
  }
  .ant-menu-item-group-title {
    font-family: Ciutadella;
    font-size: 16px;
    font-weight: 900;
  }
  .ant-menu-submenu {
    span {
      font-family: Ciutadella;
      font-size: 16px;
      font-weight: 900;
    }
  }
  .ant-menu-item {
    padding-left: 40px !important;
    background-color: #4f4532;
    span {
      font-family: CiutadellaLight;
      font-size: 16px;
      font-weight: 200;
    }
  }
  .ant-menu-submenu-title {
    padding-left: 20px !important;
    background-color: #3d331c;
  }
  .ant-menu-sub {
    background-color: #4f4532 !important;
  }
  .ant-menu-item-selected {
    background-color: #957e53 !important;
  }
`;
