import _ from 'lodash';
import moment from 'moment';
import update from 'react-addons-update';
import { message } from 'antd';

import { languages } from './variables';

export const handleTextChange = (e, state, setState, multiLang, lang) => {
  if (multiLang) {
    setState({
      ...state,
      [lang]: e.target.value
    });
  } else {
    setState(e.target.value);
  }
};

export const handleValueChange = (e, setState) => {
  setState(e.target.value);
};

export const handleNumberChange = (e, setState) => {
  setState(_.toNumber(e.target.value));
};

export const handleDateRangeChange = (
  dates,
  dateStrings,
  fullData,
  setData,
  filteredField
) => {
  if (_.get(dates, 'length') > 0) {
    const result = _.filter(fullData, item => {
      const dateToFiltered = _.get(item, filteredField);
      return (
        dateToFiltered && _.inRange(moment(dateToFiltered), dates[0], dates[1])
      );
    });
    setData(result);
  } else {
    setData(fullData);
  }
};

export const handleSwitchChange = (
  checked,
  setState,
  childKey,
  state,
  setForceUpdate
) => {
  if (childKey) {
    if (!state) {
      state = {};
    }
    state[childKey] = checked;
    setState(state);
    if (setForceUpdate) {
      setForceUpdate && setForceUpdate(Math.random());
    }
  } else {
    setState(checked);
  }
};

export const handleSelectChange = (
  e,
  setState,
  fullOptions,
  setRealValue,
  realValueKeys
) => {
  setState(e);
  if (setRealValue && fullOptions && realValueKeys) {
    const tmpRealValueObject = _.find(fullOptions, o => _.get(o, '_id') === e);
    if (tmpRealValueObject) {
      const realValueObject = {};
      _.map(realValueKeys, r => {
        realValueObject[r] = _.get(tmpRealValueObject, r);
      });
      setRealValue(realValueObject);
    }
  }
};

export const handleCheckChange = (e, setState) => {
  setState(e.target.checked);
};

export const handleDateChange = (date, dateString, setState) => {
  setState(moment(date).format('YYYY-MM-DD'));
};

export const handleColorPickerChange = (e, setState) => {
  setState(_.get(e, 'rgb'));
};

export const handleSectionSelectChange = (e, history) => {
  history.push(e.target.value);
};

export const handleTransferChange = (valuesForTransfer, valueKey, setState) => (
  remainSelectedKeys,
  direction
) => {
  const result = _.map(remainSelectedKeys, r => {
    return _.find(valuesForTransfer, v => {
      return _.get(v, valueKey) === r;
    });
  });
  setState(result);
};

export const handleEditorChange = (e, state, setState, multiLang, lang) => {
  if (multiLang) {
    setState({
      ...state,
      [lang]: e.editor.getData()
    });
  } else {
    setState(e.editor.getData());
  }
};

export const handleFileChange = (
  e,
  state,
  setState,
  setIsFileOverLimit,
  maxFileSizeByte = 2097152,
  isMultiple
) => {
  if (isMultiple) {
    const tmpNewState = state || [];
    const files = _.get(e, 'target.files');
    const totalFiles = _.get(files, 'length');
    let validFileCount = 0;
    _.map(files, file => {
      const fileName = _.get(file, 'name');
      const isIncluded = _.find(
        tmpNewState,
        t => _.get(t, 'title') === fileName || _.get(t, 'name') === fileName
      );
      if (!isIncluded) {
        const fileSize = _.get(file, 'size');
        if (fileSize <= maxFileSizeByte) {
          validFileCount += 1;
          if (validFileCount === totalFiles) {
            setIsFileOverLimit(false);
            const newState = _.concat(tmpNewState, ...files);
            setState(newState);
          }
        } else {
          setIsFileOverLimit(true);
        }
      } else {
        message.error(`Duplicate file ${fileName}, please rename.`);
      }
    });
  } else {
    const file = _.get(e, 'target.files.0');
    const fileSize = _.get(file, 'size');
    if (fileSize <= maxFileSizeByte) {
      setIsFileOverLimit(false);
      setState(file);
    } else {
      setIsFileOverLimit(true);
    }
  }
};

export const handleDynamicTextChange = (
  e,
  childKey,
  position,
  state,
  setState,
  multiLang,
  lang,
  setForceUpdate
) => {
  if (multiLang) {
    // setState({
    //   ...state,
    //   [lang]: update(state[lang], {
    //     [position]: { [childKey]: { $set: e.target.value } }
    //   })
    // });
    if (!state) {
      state = [];
    }
    if (!_.get(state, `${position}`)) {
      state[position] = {};
    }
    if (!_.get(state, `${position}.${childKey}`)) {
      state[position][childKey] = {};
    }
    state[position][childKey][lang] = e.target.value;
    setState(state);
    if (setForceUpdate) {
      setForceUpdate(Math.random());
    }
  } else {
    // setState(
    // update(state, { [position]: { [childKey]: { $set: e.target.value } } })
    // );
    if (!state) {
      state = [];
    }
    if (!_.get(state, `${position}`)) {
      state[position] = {};
    }
    state[position][childKey] = e.target.value;
    setState(state);
    if (setForceUpdate) {
      setForceUpdate(Math.random());
    }
  }
};

export const handleDynamicFileChange = (
  e,
  childKey,
  position,
  state,
  setState,
  multiLang,
  setForceUpdate
) => {
  const file = _.get(e, 'target.files.0');
  if (multiLang) {
    // setState({
    //   ...state,
    //   en: update(state['en'], { [position]: { [childKey]: { $set: file } } })
    // });
    if (!state) {
      state = [];
    }
    if (!_.get(state, `${position}`)) {
      state[position] = {};
    }
    if (!_.get(state, `${position}.${childKey}`)) {
      state[position][childKey] = {};
    }
    state[position][childKey]['en'] = file;
    setState(state);
    if (setForceUpdate) {
      setForceUpdate(Math.random());
    }
  } else {
    // setState(update(state, { [position]: { [childKey]: { $set: file } } }));
    if (!state) {
      state = [];
    }
    if (!_.get(state, `${position}`)) {
      state[position] = {};
    }
    state[position][childKey] = file;
    setState(state);
    if (setForceUpdate) {
      setForceUpdate(Math.random());
    }
  }
};

export const handleDynamicFileClear = (
  childKey,
  position,
  state,
  setState,
  multiLang,
  lang,
  setForceUpdate
) => {
  if (multiLang) {
    // setState({
    //   ...state,
    //   [lang]: update(state[lang], { [position]: { [childKey]: { $set: '' } } })
    // });
    if (!state) {
      state = [];
    }
    if (!_.get(state, `${position}`)) {
      state[position] = {};
    }
    if (!_.get(state, `${position}.${childKey}`)) {
      state[position][childKey] = {};
    }
    state[position][childKey][lang] = '';
    setState(state);
    if (setForceUpdate) {
      setForceUpdate(Math.random());
    }
  } else {
    // setState(update(state, { [position]: { [childKey]: { $set: '' } } }));
    if (!state) {
      state = [];
    }
    if (!_.get(state, `${position}`)) {
      state[position] = {};
    }
    state[position][childKey] = '';
    setState(state);
    if (setForceUpdate) {
      setForceUpdate(Math.random());
    }
  }
};

export const handleNewDynamicInput = (
  state,
  setState,
  multiLang,
  setForceUpdate
) => {
  // if (multiLang) {
  //   _.map(languages, langObj => {
  //     const lang = _.get(langObj, 'key');
  //     state[lang].push({});
  //     setState(state);
  //     setForceUpdate && setForceUpdate(Math.random());
  //   });
  // } else {
  //   state.push({});
  //   setState(state);
  //   setForceUpdate && setForceUpdate(Math.random());
  // }
  if (!state) {
    state = [];
  }
  state.push({});
  setState(state);
  setForceUpdate && setForceUpdate(Math.random());
};

export const handleDeleteDynamicInput = (
  state,
  setState,
  multiLang,
  position,
  setForceUpdate
) => {
  // if (multiLang) {
  // _.map(languages, langObj => {
  //   const lang = _.get(langObj, 'key');
  //   const stateLength = _.get(state, `${lang}.length`);
  //   if (stateLength > 1 && position < stateLength) {
  //     state[lang].splice(position, 1);
  //     setState(state);
  //     setForceUpdate && setForceUpdate(Math.random());
  //   }
  // });
  // } else {
  //   const stateLength = _.get(state, 'length');
  //   if (stateLength > 1 && position < stateLength) {
  //     state.splice(position, 1);
  //     setState(state);
  //     setForceUpdate && setForceUpdate(Math.random());
  //   }
  // }
  const stateLength = _.get(state, 'length');
  if (stateLength > 1 && position < stateLength) {
    state.splice(position, 1);
    setState(state);
    setForceUpdate && setForceUpdate(Math.random());
  }
};
