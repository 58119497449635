import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { sendGet } from '../../../utils/requestHandle';
import { statusOptions } from '../../../utils/variables';

const path = 'home/search/edit';
const rootPath = 'home';
const dataKey = 'search';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  const [forceUpdate, setForceUpdate] = useState();
  useEffect(() => {
    if (action === 'Edit') {
      setIsLoading(true);
      async function fetchDoc(url) {
        const result = await sendGet(url);
        set_id(_.get(result, 'data.data._id'));
        const data =
          dataKey !== ''
            ? _.get(result, `data.data.${dataKey}`, [])
            : _.get(result, 'data.data', []);
        setBackgroundImage(_.get(data, 'backgroundImage'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Search Section`}
        inputFields={[
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x960',
            previewWidth: '400px'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${rootPath}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: dataKey
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
