import _ from 'lodash';
import axios from 'axios';
import { handleUpdate } from './actionHandle';
import { storageRef } from './firebase';
import { sendGet, sendPost } from './requestHandle';
import { projectSectionNames, projectSectionOrders } from './variables';

export const randomHex = (length) => {
  let result = '';
  const characters = 'abcdef0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const randomBase64 = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const showModalFullImageFromUrl = (url) => {
  const modal = document.getElementById('modal-content');
  if (modal) {
    const img = document.createElement('img');
    img.classList.add('modal-content-image');
    img.src = _.includes(url, process.env.REACT_APP_API_HOST)
      ? url
      : process.env.REACT_APP_API_HOST + url;
    img.alt = url;
    img.onclick = () => {
      modal.style.display = 'none';
    };
    modal.innerHTML = '';
    modal.appendChild(img);
    modal.style.display = 'flex';
  }
};

export const showModalFullImageFromFile = (file) => {
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      showModalFullImageFromUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  }
};

export const removePreviewFileFromList = (
  elementId,
  position,
  state,
  setState,
  setForceUpdate
) => {
  const removedElement = document.getElementById(elementId);
  if (position >= 0 && position < _.get(state, 'length')) {
    if (removedElement) {
      removedElement.remove();
    }
    deleteFileWithUrl(_.get(state, `${position}.url`));
    state.splice(position, 1);
    setState(state);
    setForceUpdate && setForceUpdate(Math.random());
  }
};

export const appendPreviewImage = (
  key,
  position,
  name,
  value,
  isFile,
  state,
  setState,
  previewWidth,
  setForceUpdate
) => {
  const previewImageListContainer = document.getElementById(
    `preview-image-list-container-${key}`
  );
  const singleContainer = document.getElementById(
    `preview-image-list-single-container-${key}-${name}`
  );
  if (singleContainer) {
    singleContainer.remove();
  }
  if (previewImageListContainer) {
    const img = document.createElement('img');
    img.id = `preview-image-list-${key}-${name}`;
    img.style.cssText = `width: ${previewWidth}; height: auto; margin-top: 10px; background-color: #ddd2ca`;
    img.src = process.env.REACT_APP_API_HOST + value;
    img.alt = name;
    img.onclick = () =>
      isFile
        ? showModalFullImageFromFile(_.get(state, position))
        : showModalFullImageFromUrl(value);

    const xmlns = 'http://www.w3.org/2000/svg';
    const path = document.createElementNS(xmlns, 'path');
    path.setAttributeNS(
      null,
      'd',
      'M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z'
    );

    const svg = document.createElementNS(xmlns, 'svg');
    svg.setAttributeNS(null, 'viewBox', '64 64 896 896');
    svg.setAttributeNS(null, 'width', '1em');
    svg.setAttributeNS(null, 'height', '1em');
    svg.setAttributeNS(null, 'fill', 'currentColor');
    svg.appendChild(path);

    const elementId = `preview-image-list-single-container-${key}-${name}`;
    const i = document.createElement('i');
    i.classList.add('anticon');
    i.classList.add('anticon-delete');
    i.classList.add('delete-icon');
    i.appendChild(svg);
    i.onclick = () =>
      removePreviewFileFromList(
        elementId,
        position,
        state,
        setState,
        setForceUpdate
      );

    const singleContainer = document.createElement('div');
    singleContainer.id = elementId;
    singleContainer.appendChild(img);
    singleContainer.appendChild(i);
    previewImageListContainer.appendChild(singleContainer);
  }
};

export const previewImage = (file, elementId) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = document.getElementById(elementId);
    if (img) {
      img.setAttribute('src', e.target.result);
    }
  };
  reader.readAsDataURL(file);
};

export const previewImageWithIdList = (file, elementIds) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    _.map(elementIds, (elementId) => {
      const img = document.getElementById(elementId);
      if (img) {
        img.setAttribute('src', e.target.result);
      }
    });
  };
  reader.readAsDataURL(file);
};

export const appendPreviewFile = (
  key,
  position,
  name,
  value,
  isFile,
  state,
  setState,
  setForceUpdate
) => {
  const previewFileListContainer = document.getElementById(
    `preview-file-list-container-${key}`
  );
  const alreadyHaveSingleContainer = document.getElementById(
    `preview-file-list-single-container-${key}-${name}`
  );
  if (previewFileListContainer && !alreadyHaveSingleContainer) {
    const a = document.createElement('a');
    a.id = `preview-file-list-${key}-${name}`;
    a.innerHTML = name;
    a.style.cssText = `pointer-events: none;`;
    if (!isFile) {
      a.href = process.env.REACT_APP_API_HOST + value;
      a.target = '_blank';
      a.style.cssText = `pointer-events: all; color: #957e53;`;
    }

    const xmlns = 'http://www.w3.org/2000/svg';
    const path = document.createElementNS(xmlns, 'path');
    path.setAttributeNS(
      null,
      'd',
      'M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z'
    );

    const svg = document.createElementNS(xmlns, 'svg');
    svg.setAttributeNS(null, 'viewBox', '64 64 896 896');
    svg.setAttributeNS(null, 'width', '1em');
    svg.setAttributeNS(null, 'height', '1em');
    svg.setAttributeNS(null, 'fill', 'currentColor');
    svg.appendChild(path);

    const elementId = `preview-file-list-single-container-${key}-${name}`;
    const i = document.createElement('i');
    i.classList.add('anticon');
    i.classList.add('anticon-delete');
    i.classList.add('delete-icon');
    i.appendChild(svg);
    i.onclick = () =>
      removePreviewFileFromList(
        elementId,
        position,
        state,
        setState,
        setForceUpdate
      );

    const singleContainer = document.createElement('div');
    singleContainer.id = elementId;
    singleContainer.appendChild(a);
    singleContainer.appendChild(i);
    previewFileListContainer.appendChild(singleContainer);
  }
};

export const handleTableChange = (
  setPageSize,
  setData,
  setInitialRowIndexToDrag
) => (pagination, filters, sorter, data) => {
  const pageSize = _.get(pagination, 'pageSize');
  setPageSize(pageSize);
  setData(_.get(data, 'currentDataSource'));
  setInitialRowIndexToDrag((_.get(pagination, 'current') - 1) * pageSize);
  localStorage.setItem('pageSize', pageSize);
};

export const onDragEnd = (
  data,
  setData,
  initialRowIndexToDrag,
  setIsLoading,
  url,
  rootId,
  childKey
) => (tmpFromIndex, tmpToIndex) => {
  console.log('xxx fromIndex', tmpFromIndex);
  console.log('xxx toIndex', tmpToIndex);
  // console.log('xxx initialRowIndexToDrag', initialRowIndexToDrag);
  const shouldUpdateChildKey = rootId && childKey;
  const fromIndex = initialRowIndexToDrag + tmpFromIndex;
  const toIndex = initialRowIndexToDrag + tmpToIndex;
  const shouldUpdateCount = Math.abs(fromIndex - toIndex) + 1;
  // console.log('xxx shouldUpdateCount', shouldUpdateCount);
  let updateSuccessCount = 0;
  if (fromIndex < toIndex) {
    let shouldDecrementOrder = true;
    if (
      _.toNumber(_.get(data, `${fromIndex}.order`)) >
      _.toNumber(_.get(data, `${toIndex}.order`))
    ) {
      shouldDecrementOrder = false;
    }
    for (let i = fromIndex; i <= toIndex; i++) {
      if (i === fromIndex) {
        data[i].order = _.toNumber(data[toIndex].order);
        setIsLoading(true);
      } else {
        if (shouldDecrementOrder) {
          // console.log('fromIndex < toIndex xxx decrement between');
          data[i].order = _.toNumber(data[i].order) - 1;
        } else {
          // console.log('fromIndex < toIndex xxx increment between');
          data[i].order = _.toNumber(data[i].order) + 1;
        }
      }
      if (!shouldUpdateChildKey) {
        const updatedData = {};
        updatedData._id = _.get(data, `${i}._id`);
        updatedData.order = _.get(data, `${i}.order`);
        handleUpdate(url, updatedData)
          // eslint-disable-next-line no-loop-func
          .then(() => {
            updateSuccessCount = updateSuccessCount + 1;
            if (updateSuccessCount === shouldUpdateCount) {
              // console.log('xxx data before splice', data);
              const item = data.splice(fromIndex, 1)[0];
              // console.log('xxx data to insert', item);
              // console.log('xxx data before insert item', data);
              data.splice(toIndex, 0, item);
              // console.log('xxx data after splice', data);
              setData(data);
              setIsLoading(false);
            }
          });
      }
    }
    if (shouldUpdateChildKey) {
      const updatedData = {};
      updatedData['_id'] = rootId;
      updatedData['data'] = data;
      updatedData['dataKey'] = childKey;
      handleUpdate(url, updatedData).then(() => {
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        setData(data);
        setIsLoading(false);
      });
    }
  } else if (fromIndex > toIndex) {
    let shouldIncrementOrder = true;
    if (_.toNumber(data[fromIndex].order) < _.toNumber(data[toIndex].order)) {
      shouldIncrementOrder = false;
    }
    for (let i = fromIndex; i >= toIndex; i--) {
      if (i === fromIndex) {
        data[i].order = _.toNumber(data[toIndex].order);
        setIsLoading(true);
      } else {
        if (shouldIncrementOrder) {
          // console.log('fromIndex > toIndex xxx increment between');
          data[i].order = _.toNumber(data[i].order) + 1;
        } else {
          // console.log('fromIndex > toIndex xxx decrement between');
          data[i].order = _.toNumber(data[i].order) - 1;
        }
      }
      if (!shouldUpdateChildKey) {
        const updatedData = {};
        updatedData._id = _.get(data, `${i}._id`);
        updatedData.order = _.get(data, `${i}.order`);
        handleUpdate(url, updatedData)
          // eslint-disable-next-line no-loop-func
          .then(() => {
            updateSuccessCount = updateSuccessCount + 1;
            if (updateSuccessCount === shouldUpdateCount) {
              const item = data.splice(fromIndex, 1)[0];
              data.splice(toIndex, 0, item);
              setData(data);
              setIsLoading(false);
            }
          });
      }
    }
    if (shouldUpdateChildKey) {
      const updatedData = {};
      updatedData['_id'] = rootId;
      updatedData['data'] = data;
      updatedData['dataKey'] = childKey;
      handleUpdate(url, updatedData).then(() => {
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        setData(data);
        setIsLoading(false);
      });
    }
  }
};

export const uploadToFirebaseStorageAndSetUrl = async (
  file,
  path,
  updatedObject,
  updatedKey,
  isMultipleObject
) => {
  const fileRef = storageRef.child(path);
  await fileRef.put(file).then(async (snapshot) => {
    await fileRef.getDownloadURL().then((url) => {
      if (isMultipleObject) {
        _.map(updatedObject, (o) => {
          o[updatedKey] = url;
        });
      } else {
        updatedObject[updatedKey] = url;
      }
    });
  });
};

export const uploadToServerAndSetPath = async (
  file,
  path,
  updatedObject,
  updatedKey,
  isMultipleObject,
  isDynamicInput,
  languages
) => {
  let data = new FormData();
  data.append('file', file);
  data.append('fileName', path);
  const response = await sendPost(
    `${process.env.REACT_APP_API_HOST}/upload`,
    data,
    'multipart/form-data'
  );
  const assetUrl = _.get(response, 'data.data.uploadedUrl');
  if (isMultipleObject) {
    _.map(updatedObject, (o) => {
      o[updatedKey] =
        assetUrl || `${process.env.REACT_APP_UPLOAD_PATH}/${path}`;
    });
  } else if (isDynamicInput && languages) {
    _.map(languages, (lang) => {
      console.log('lang of ', lang);
      updatedObject[updatedKey][_.get(lang, 'key')] =
        assetUrl || `${process.env.REACT_APP_UPLOAD_PATH}/${path}`;
    });
  } else {
    updatedObject[updatedKey] =
      assetUrl || `${process.env.REACT_APP_UPLOAD_PATH}/${path}`;
  }
};

export const getMax = (field, data) => {
  if (field && data) {
    const max = Math.max.apply(
      Math,
      data.map((o) => {
        return _.get(o, field, 0);
      })
    );
    return max;
  }
};

export const getNext = (field, data) => {
  if (field && data) {
    const max = getMax(field, data);
    const next = max >= 0 ? max + 1 : 1;
    return next;
  }
};

export const fetchNextOrderOfCollection = (url, setOrder, setIsLoading) => {
  if (url && setOrder) {
    async function fetchMaxOrder(url) {
      const result = await sendGet(url);
      const maxOrder = _.get(result, 'data.data.0.order');
      const maxOrderNumber = _.toNumber(maxOrder);
      const nextOrder = maxOrderNumber >= 0 ? maxOrderNumber + 1 : 1;
      setOrder(nextOrder);
      setIsLoading(false);
    }
    fetchMaxOrder(url);
  }
};

export const fetchNextOrderOfSubCollection = (url, setOrder, setIsLoading) => {
  if (url && setOrder) {
    async function fetchMaxOrder(url) {
      const result = await sendGet(url);
      const maxOrder = _.get(result, 'data.data');
      const maxOrderNumber = _.toNumber(maxOrder);
      const nextOrder = maxOrderNumber >= 0 ? maxOrderNumber + 1 : 1;
      setOrder(nextOrder);
      setIsLoading(false);
    }
    fetchMaxOrder(url);
  }
};

export const createFuseOption = (searchKeys) => {
  return {
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    threshold: 0.1,
    tokenize: false,
    matchAllTokens: true,
    keys: searchKeys,
  };
};

export const sortObjectKey = (unordered, direction) => {
  const ordered = {};
  switch (direction) {
    case 'desc':
      Object.keys(unordered)
        .reverse()
        .forEach((key) => {
          ordered[key] = unordered[key];
        });
      break;
    default:
      Object.keys(unordered)
        .sort()
        .forEach((key) => {
          ordered[key] = unordered[key];
        });
      break;
  }
  unordered = ordered;
};

export const sortArrayWithKey = (array, sortedKey, direction) => {
  array.sort((a, b) => {
    const valueA = _.get(a, sortedKey);
    const valueB = _.get(b, sortedKey);
    if (valueA < valueB) return direction === 'desc' ? 1 : -1;
    if (valueA > valueB) return direction === 'desc' ? -1 : 1;
    return 0;
  });
};

export const appendSectionSwitchInputs = (
  rootKey,
  tmpInputFields,
  showSections,
  setShowSections,
  setForceUpdate,
  nameMapObject = projectSectionNames
) => {
  for (let key in nameMapObject) {
    if (nameMapObject.hasOwnProperty(key)) {
      tmpInputFields.push({
        key: rootKey,
        state: showSections,
        setState: setShowSections,
        label: _.get(nameMapObject, key),
        type: 'switch',
        showOnTop: true,
        childKey: key,
        setForceUpdate: setForceUpdate,
      });
    }
  }
};

export const appendSectionSelectOptions = (
  sectionSelectOptions,
  showSections,
  initialPath,
  nameMapObject = projectSectionNames,
  orderMapObject = projectSectionOrders
) => {
  for (let key in showSections) {
    if (showSections.hasOwnProperty(key) && _.get(showSections, key) === true) {
      sectionSelectOptions.push({
        key: key,
        name: _.get(nameMapObject, key),
        value: `${initialPath}/${key}`,
        order: _.get(orderMapObject, key),
      });
    }
  }
};

export const cloneDataToNewKey = (data, keyArray) => {
  _.map(data, (d) => {
    _.map(keyArray, (k) => {
      d[_.get(k, 'newKey')] = _.get(d, _.get(k, 'oldKey'));
    });
  });
};

export const isValidUrl = (string) => {
  if (typeof string === 'string') {
    const result = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return result !== null;
  } else {
    return false;
  }
};

export const isFile = (file) => {
  return typeof _.get(file, 'name') === 'string';
};

export const deleteFileWithUrl = (url) => {
  if (url && !isFile(url)) {
    axios
      .delete(`${process.env.REACT_APP_UPLOAD_PATH}/delete-with-url`, {
        data: { url: url },
      })
      .then((response) => {
        console.log('sendDelete response', _.get(response, 'status'), response);
        return response;
      })
      .catch((err) => {
        console.log('sendDelete error', err);
        return false;
      });
  }
};

export const showModalWithData = (data, setDataForModal, setModalVisible) => {
  setDataForModal(data);
  setModalVisible(true);
};

export const hideModal = (setModalVisible) => {
  setModalVisible(false);
};
