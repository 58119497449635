import _ from 'lodash';

export default (state, action) => {
  const user = _.get(action, 'user');
  switch (action.type) {
    case 'USERS/INIT':
      return {
        ...state,
        ...user
      };
    case 'USERS/LOGOUT':
      return {
        ...state,
        user: null
      };
    default:
      return {
        ...state
      };
  }
};
