import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Icon, Modal, Button } from 'antd';

import Styled from './styled';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import { showModalWithData, hideModal } from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;

const path = 'form-submit';

function Index() {
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [dataForModal, setDataForModal] = useState();
  const [modalVisible, setModalVisible] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchList(url) {
      const result = await sendGet(url);
      const tmpData = _.get(result, 'data.data');
      const data = _.filter(tmpData, t => _.get(t, 'page') === 'contact-us');
      const newData = _.map(data, d => {
        d.createdDate = moment(_.get(d, 'createdAt')).format('YYYY-MM-DD');
        d.createdTime = moment(_.get(d, 'createdAt')).format('HH:ss');
        return d;
      });
      setFullData(newData);
      setData(newData);
      setIsLoading(false);
    }
    fetchList(`${process.env.REACT_APP_API_HOST}/${path}/list`);
  }, []);
  const columns = [
    {
      title: 'About',
      dataIndex: 'about',
      sorter: (a, b) => _.get(a, 'about').localeCompare(_.get(b, 'about'))
    },
    {
      title: 'First Name',
      dataIndex: 'firstname',
      sorter: (a, b) =>
        _.get(a, 'firstname').localeCompare(_.get(b, 'firstname'))
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      sorter: (a, b) => _.get(a, 'lastname').localeCompare(_.get(b, 'lastname'))
    },
    {
      title: 'Telephone',
      dataIndex: 'telephone',
      sorter: (a, b) =>
        _.get(a, 'telephone').localeCompare(_.get(b, 'telephone'))
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => _.get(a, 'email').localeCompare(_.get(b, 'email'))
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return moment(text).format('YYYY-MM-DD');
      },
      sorter: (a, b) => {
        const ca = moment(_.get(a, 'createdAt')).format('YYYY-MM-DD');
        const cb = moment(_.get(b, 'createdAt')).format('YYYY-MM-DD');
        return ca.localeCompare(cb);
      }
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return moment(text).format('HH:ss');
      },
      sorter: (a, b) => {
        const ca = moment(_.get(a, 'createdAt')).format('HH:ss');
        const cb = moment(_.get(b, 'createdAt')).format('HH:ss');
        return ca.localeCompare(cb);
      }
    },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton
            path={`/contact-us/${path}/edit/${_.get(record, '_id')}`}
          />
          <Icon
            type='zoom-in'
            style={{ color: '#1854b5', marginRight: '20px' }}
            onClick={() =>
              showModalWithData(record, setDataForModal, setModalVisible)
            }
          />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_.get(record, '_id')}
            data={data}
            setData={setData}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        title='Form Submit List'
        searchKeys={[
          '_id',
          'firstname',
          'lastname',
          'telephone',
          'email',
          'about',
          'subject',
          'detail',
          'createdAt'
        ]}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          {
            type: 'dateRange',
            inputOption: {
              filteredField: 'createdAt',
              fullState: fullData,
              setState: setData,
              hideLabel: true,
              hideContainer: true
            }
          },
          {
            type: 'excel',
            fileName: 'Contact Us',
            columns: [
              {
                title: 'First Name',
                value: 'firstname',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Last Name',
                value: 'lastname',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Telephone',
                value: 'telephone',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Email',
                value: 'email',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'About',
                value: 'about',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Subject',
                value: 'subject',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Detail',
                value: 'detail',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Date Created',
                value: 'createdDate',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Time Created',
                value: 'createdTime',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              }
            ],
            valueKeys: [
              'firstname',
              'lastname',
              'telephone',
              'email',
              'about',
              'subject',
              'detail',
              'createdDate',
              'createdTime'
            ],
            data: data,
            sheetName: 'Form Submit'
          }
        ]}
      />
      <Modal
        title='Full Detail'
        visible={modalVisible}
        onCancel={() => hideModal(setModalVisible)}
        footer={[
          <Button key='back' onClick={() => hideModal(setModalVisible)}>
            Return
          </Button>
        ]}
      >
        <p>
          <b>First Name: </b>
          {_.get(dataForModal, 'firstname')}
        </p>
        <p>
          <b>Last Name: </b>
          {_.get(dataForModal, 'lastname')}
        </p>
        <p>
          <b>Email: </b>
          {_.get(dataForModal, 'email')}
        </p>
        <p>
          <b>Telephone: </b>
          {_.get(dataForModal, 'telephone')}
        </p>
        <p>
          <b>About: </b>
          {_.get(dataForModal, 'about')}
        </p>
        <p>
          <b>Subject: </b>
          {_.get(dataForModal, 'subject')}
        </p>
        <p>
          <b>Detail: </b>
          {_.get(dataForModal, 'detail')}
        </p>
        <p>
          <b>Date: </b>
          {moment(_.get(dataForModal, 'createdAt', new Date())).format(
            'YYYY-MM-DD'
          )}
        </p>
        <p>
          <b>Time: </b>
          {moment(_.get(dataForModal, 'createdAt', new Date())).format('HH:ss')}
        </p>
      </Modal>
    </Styled>
  );
}

export default Index;
