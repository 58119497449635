import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import {
  backgroundTypeOptions,
  projectSectionNames
} from '../../../utils/variables';
import {
  appendSectionSwitchInputs,
  appendSectionSelectOptions,
  sortArrayWithKey
} from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;

const rootPath = 'project';
const outerDataKey = 'construction';
const dataKey = 'construction.constructions';

function Index(props) {
  const action = 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const path = `project/edit/${_id}/construction`;
  const [title, setTitle] = useState();
  const [tagLine, setTagLine] = useState();
  const [backgroundType, setBackgroundType] = useState('Image');
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundColor, setBackgroundColor] = useState();
  const [image, setImage] = useState();
  const [gallery, setGallery] = useState();
  const [updated, setUpdated] = useState();
  const [overallPercent, setOverallPercent] = useState();
  // const [progressList, setProgressList] = useState();
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSections, setShowSections] = useState();
  const tmpInputFields = [];
  const [forceUpdate, setForceUpdate] = useState();
  appendSectionSwitchInputs(
    'showSections',
    tmpInputFields,
    showSections,
    setShowSections,
    setForceUpdate
  );
  const sectionSelectOptions = [
    { name: projectSectionNames.default, value: `/project/edit/${_id}` }
  ];
  appendSectionSelectOptions(
    sectionSelectOptions,
    showSections,
    `/project/edit/${_id}`
  );
  sortArrayWithKey(sectionSelectOptions, 'order');
  useEffect(() => {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      const outerData =
        outerDataKey !== ''
          ? _.get(result, `data.data.${outerDataKey}`, [])
          : '';
      setTitle(_.get(outerData, 'title'));
      setTagLine(_.get(outerData, 'tagLine'));
      setBackgroundType(_.get(outerData, 'backgroundType', 'Image'));
      setBackgroundImage(_.get(outerData, 'backgroundImage'));
      setBackgroundColor(_.get(outerData, 'backgroundColor'));
      setImage(_.get(outerData, 'image'));
      setUpdated(_.get(outerData, 'updated'));
      setOverallPercent(_.get(outerData, 'overallPercent'));
      // setProgressList(_.get(outerData, 'progressList'));
      setGallery(_.get(outerData, 'gallery'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      sortArrayWithKey(data, 'order');
      setShowSections(_.get(result, 'data.data.showSections'));
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/view/${_id}`);
  }, []);
  const columns = [
    { title: 'Title', dataIndex: 'title.en' },
    { title: 'Status', dataIndex: 'status' },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/edit/${_.get(record, '_id')}`} />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_id}
            data={data}
            setData={setData}
            childId={_.get(record, '_id')}
            dataKey={dataKey}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        autoHeight={true}
        title='Construction List'
        sectionSelectInfo={{
          label: 'Sections',
          defaultValue: `/${path}`,
          sections: sectionSelectOptions
        }}
        searchKeys={['_id', 'title.en', 'status']}
        rootId={_id}
        childKey={dataKey}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/new` }
        ]}
      />
      <CustomForm
        autoHeight={true}
        action={action}
        title={`${action} Construction Section`}
        inputFields={[
          // { key: 'sectionSelect', label: 'Sections', type: 'sectionSelect', defaultValue: `/${path}`, sections: sectionSelectOptions },
          {
            key: 'title',
            state: title,
            setState: setTitle,
            label: 'Title',
            multiLang: true,
            type: 'text'
          },
          {
            key: 'tagLine',
            state: tagLine,
            setState: setTagLine,
            label: 'Tag Line',
            multiLang: true,
            type: 'text'
          },
          {
            key: 'backgroundType',
            state: backgroundType,
            setState: setBackgroundType,
            label: 'Background Type',
            type: 'select',
            valuesForSelect: backgroundTypeOptions
          },
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x1080',
            previewWidth: '400px'
          },
          {
            key: 'backgroundColor',
            state: backgroundColor,
            setState: setBackgroundColor,
            label: 'Background Color',
            type: 'colorPicker'
          },
          {
            key: 'image',
            state: image,
            setState: setImage,
            label: 'Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1500x550',
            previewWidth: '400px'
          },
          {
            key: 'gallery',
            state: gallery,
            setState: setGallery,
            label: 'Gallery',
            type: 'file',
            setForceUpdate: setForceUpdate,
            isMultiple: true,
            previewType: 'image-list'
          },
          {
            key: 'updated',
            state: updated,
            setState: setUpdated,
            label: 'Updated',
            type: 'date'
          },
          {
            key: 'overallPercent',
            state: overallPercent,
            setState: setOverallPercent,
            label: 'Overall Percent',
            type: 'text'
          }
          // {
          //   key: 'progressList',
          //   state: progressList,
          //   setState: setProgressList,
          //   label: 'Progress List',
          //   multiLang: true,
          //   type: 'dynamic',
          //   setForceUpdate: setForceUpdate,
          //   hideImageInput: true,
          //   inline: true
          // }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: outerDataKey,
            updateOuter: true
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default Index;
