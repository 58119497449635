import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Styled from './styled';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import { cloneDataToNewKey } from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;

const path = 'news/article';

function Index() {
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchList(url) {
      const result = await sendGet(url);
      const data = _.get(result, 'data.data');
      cloneDataToNewKey(data, [{ oldKey: 'title.en', newKey: 'titleEn' }]);
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchList(`${process.env.REACT_APP_API_HOST}/${path}/list`);
  }, []);
  const columns = [
    { title: 'Title', dataIndex: 'title.en' },
    { title: 'Published Date', dataIndex: 'publishedDate' },
    { title: 'Status', dataIndex: 'status' },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/edit/${_.get(record, '_id')}`} />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_.get(record, '_id')}
            data={data}
            setData={setData}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        title='News List'
        searchKeys={['_id', 'title.en', 'status', 'publishedDate']}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/new` }
        ]}
      />
    </Styled>
  );
}

export default Index;
