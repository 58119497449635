import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Icon, Menu } from 'antd';

import Styled from './styled';
import { handlePath, handleLogout } from '../../utils/actionHandle';

const SubMenu = Menu.SubMenu;

export default withRouter(function SideMenu(props) {
  const history = _.get(props, 'history');
  const allowPaths = localStorage.getItem('allowPaths');
  return (
    <Styled
      style={{ display: _.get(props, 'isLogin') === 'true' ? 'block' : 'none' }}
    >
      <div className='logo-container'>
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}logo.png`}
          alt='Logo'
          className='logo'
        />
      </div>
      {/* <Menu defaultSelectedKeys={['contact-us/edit']} defaultOpenKeys={['contact-us']} mode="inline" theme="dark"> */}
      <Menu mode='inline' theme='dark'>
        {_.includes(allowPaths, 'career') && (
          <SubMenu
            key='career'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Career</span>
              </span>
            }
          >
            <Menu.Item
              key='career/banner/edit'
              onClick={() => handlePath(history, '/career/banner/edit')}
            >
              <span>Banner Section</span>
            </Menu.Item>
            <Menu.Item
              key='career/privilege'
              onClick={() => handlePath(history, '/career/privilege')}
            >
              <span>Privilege Section</span>
            </Menu.Item>
            <Menu.Item
              key='career/position'
              onClick={() => handlePath(history, '/career/position')}
            >
              <span>Position Section</span>
            </Menu.Item>
            <Menu.Item
              key='career/form-submit'
              onClick={() => handlePath(history, '/career/form-submit')}
            >
              <span>Apply for a job</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'contact-us') && (
          <SubMenu
            key='contact-us'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Contact Us</span>
              </span>
            }
          >
            <Menu.Item
              key='contact-us/banner/edit'
              onClick={() => handlePath(history, '/contact-us/banner/edit')}
            >
              <span>Banner Section</span>
            </Menu.Item>
            <Menu.Item
              key='contact-us/contact'
              onClick={() => handlePath(history, '/contact-us/contact')}
            >
              <span>Contact Section</span>
            </Menu.Item>
            <Menu.Item
              key='contact-us/map'
              onClick={() => handlePath(history, '/contact-us/map/edit')}
            >
              <span>Map Section</span>
            </Menu.Item>
            <Menu.Item
              key='contact-us/form-submit'
              onClick={() => handlePath(history, '/contact-us/form-submit')}
            >
              <span>Form Submit</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'disclaimer') && (
          <SubMenu
            key='disclaimer'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Disclaimer</span>
              </span>
            }
          >
            <Menu.Item
              key='disclaimer/edit'
              onClick={() => handlePath(history, '/disclaimer/edit')}
            >
              <span>Page detail</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'email-receiver') && (
          <SubMenu
            key='email-receiver'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Email Receiver</span>
              </span>
            }
          >
            <Menu.Item
              key='email-receiver'
              onClick={() => handlePath(history, '/email-receiver')}
            >
              <span>List</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'home') && (
          <SubMenu
            key='home'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Home</span>
              </span>
            }
          >
            <Menu.Item
              key='home/banner'
              onClick={() => handlePath(history, '/home/banner')}
            >
              <span>Banner Section</span>
            </Menu.Item>
            {/* <Menu.Item
              key='home/search/edit'
              onClick={() => handlePath(history, '/home/search/edit')}
            >
              <span>Search Section</span>
            </Menu.Item> */}
            <Menu.Item
              key='home/promotion/edit'
              onClick={() => handlePath(history, '/home/promotion/edit')}
            >
              <span>Promotion Section</span>
            </Menu.Item>
            <Menu.Item
              key='home/about/edit'
              onClick={() => handlePath(history, '/home/about/edit')}
            >
              <span>About Section</span>
            </Menu.Item>
            <Menu.Item
              key='home/news/edit'
              onClick={() => handlePath(history, '/home/news/edit')}
            >
              <span>News Section</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'line') && (
          <SubMenu
            key='line'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Line</span>
              </span>
            }
          >
            <Menu.Item
              key='line/user'
              onClick={() => handlePath(history, '/line/user')}
            >
              <span>User</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'news') && (
          <SubMenu
            key='news'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>News</span>
              </span>
            }
          >
            <Menu.Item
              key='news/article'
              onClick={() => handlePath(history, '/news/article')}
            >
              <span>Article</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'policy') && (
          <SubMenu
            key='policy'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Policy</span>
              </span>
            }
          >
            <Menu.Item
              key='policy/edit'
              onClick={() => handlePath(history, '/policy/edit')}
            >
              <span>Page detail</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'project') && (
          <SubMenu
            key='project'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Project</span>
              </span>
            }
          >
            <Menu.Item
              key='project'
              onClick={() => handlePath(history, '/project')}
            >
              <span>List</span>
            </Menu.Item>
            <Menu.Item
              key='project/budget'
              onClick={() => handlePath(history, '/project/budget')}
            >
              <span>Budget</span>
            </Menu.Item>
            <Menu.Item
              key='project/location'
              onClick={() => handlePath(history, '/project/location')}
            >
              <span>Location</span>
            </Menu.Item>
            <Menu.Item
              key='project/price'
              onClick={() => handlePath(history, '/project/price')}
            >
              <span>Price</span>
            </Menu.Item>
            <Menu.Item
              key='project/room-type'
              onClick={() => handlePath(history, '/project/room-type')}
            >
              <span>Room Type</span>
            </Menu.Item>
            {/* <Menu.Item
              key='project/period-to-contact'
              onClick={() => handlePath(history, '/project/period-to-contact')}
            >
              <span>Period to Contact</span>
            </Menu.Item> */}
            <Menu.Item
              key='project/status'
              onClick={() => handlePath(history, '/project/status')}
            >
              <span>Status</span>
            </Menu.Item>
            <Menu.Item
              key='project/type'
              onClick={() => handlePath(history, '/project/type')}
            >
              <span>Type</span>
            </Menu.Item>
            <Menu.Item
              key='project/form-submit'
              onClick={() => handlePath(history, '/project/form-submit')}
            >
              <span>Register</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'project-progress') && (
          <SubMenu
            key='project-progress'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Project Progress</span>
              </span>
            }
          >
            <Menu.Item
              key='project-progress/banner/edit'
              onClick={() =>
                handlePath(history, '/project-progress/banner/edit')
              }
            >
              <span>Banner Section</span>
            </Menu.Item>
            <Menu.Item
              key='project-progress/progress/edit'
              onClick={() =>
                handlePath(history, '/project-progress/progress/edit')
              }
            >
              <span>Progress Section</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'promotion') && (
          <SubMenu
            key='promotion'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Promotion</span>
              </span>
            }
          >
            <Menu.Item
              key='promotion/article'
              onClick={() => handlePath(history, '/promotion/article')}
            >
              <span>Article</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'user') && (
          <SubMenu
            key='user'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>User</span>
              </span>
            }
          >
            <Menu.Item key='user' onClick={() => handlePath(history, '/user')}>
              <span>List</span>
            </Menu.Item>
          </SubMenu>
        )}
        {_.includes(allowPaths, 'subscribe') && (
          <SubMenu
            key='subscribe'
            title={
              <span className='menu-wrapper'>
                <Icon type='file-text' />
                <span>Subscribe</span>
              </span>
            }
          >
            <Menu.Item
              key='subscribe/form-submit'
              onClick={() => handlePath(history, '/subscribe/form-submit')}
            >
              <span>Form Submit</span>
            </Menu.Item>
          </SubMenu>
        )}
        <Menu.Item key='logout' onClick={handleLogout}>
          <span>Log Out</span>
        </Menu.Item>
      </Menu>
    </Styled>
  );
});
