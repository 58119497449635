import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './form_styled';
import CustomForm from '../../../components/CustomForm';
import { statusOptions, pageOptions } from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import { fetchNextOrderOfCollection } from '../../../utils/global';

const path = 'line/user';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const [isLoading, setIsLoading] = useState(false);
  const [lineId, setLineId] = useState();
  const [name, setName] = useState();
  const [page, setPage] = useState();
  const [token, setToken] = useState();
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  const [forceUpdate, setForceUpdate] = useState();
  useEffect(() => {
    setIsLoading(true);
    if (action === 'Edit') {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        setLineId(_.get(data, 'lineId'));
        setName(_.get(data, 'name'));
        setPage(_.get(data, 'page'));
        setToken(_.get(data, 'token'));
        setStatus(_.get(data, 'status', 'Show'));
        setOrder(_.get(data, 'order', 1));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/view/${_id}`);
    } else {
      fetchNextOrderOfCollection(
        `${process.env.REACT_APP_API_HOST}/${path}/max/order`,
        setOrder,
        setIsLoading
      );
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Line User`}
        inputFields={[
          {
            key: 'lineId',
            state: lineId,
            setState: setLineId,
            label: 'Line Id',
            type: 'text'
          },
          {
            key: 'name',
            state: name,
            setState: setName,
            label: 'Name',
            type: 'text'
          },
          {
            key: 'page',
            state: page,
            setState: setPage,
            label: 'Page',
            type: 'select',
            valuesForSelect: pageOptions
          },
          {
            key: 'token',
            state: token,
            setState: setToken,
            label: 'Token',
            type: 'text'
          },
          {
            key: 'status',
            state: status,
            setState: setStatus,
            label: 'Status',
            type: 'select',
            valuesForSelect: statusOptions
          },
          {
            key: 'order',
            state: order,
            setState: setOrder,
            label: 'Order',
            type: 'number'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
