import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';

import Styled from './styled';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';

const { DeleteButton } = CustomButton;

const path = 'form-submit';

function Index() {
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchList(url) {
      const result = await sendGet(url);
      const tmpData = _.get(result, 'data.data');
      const data = _.filter(tmpData, t => _.get(t, 'page') === 'subscribe');
      const newData = _.map(data, d => {
        d.createdDate = moment(_.get(d, 'createdAt')).format('YYYY-MM-DD');
        d.createdTime = moment(_.get(d, 'createdAt')).format('HH:ss');
        return d;
      });
      setFullData(newData);
      setData(newData);
      setIsLoading(false);
    }
    fetchList(`${process.env.REACT_APP_API_HOST}/${path}/list`);
  }, []);
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => _.get(a, 'email').localeCompare(_.get(b, 'email'))
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return moment(text).format('YYYY-MM-DD');
      },
      sorter: (a, b) => {
        const ca = moment(_.get(a, 'createdAt')).format('YYYY-MM-DD');
        const cb = moment(_.get(b, 'createdAt')).format('YYYY-MM-DD');
        return ca.localeCompare(cb);
      }
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return moment(text).format('HH:ss');
      },
      sorter: (a, b) => {
        const ca = moment(_.get(a, 'createdAt')).format('HH:ss');
        const cb = moment(_.get(b, 'createdAt')).format('HH:ss');
        return ca.localeCompare(cb);
      }
    },
    // { title: 'Order', dataIndex: 'order', /* defaultSortOrder: 'ascend', sorter: (a, b) => a.order - b.order */ },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_.get(record, '_id')}
            data={data}
            setData={setData}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        title='Form Submit List'
        searchKeys={['_id', 'email', 'createdAt']}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          {
            type: 'dateRange',
            inputOption: {
              filteredField: 'createdAt',
              fullState: fullData,
              setState: setData,
              hideLabel: true,
              hideContainer: true
            }
          },
          {
            type: 'excel',
            fileName: 'Subscribe',
            columns: [
              {
                title: 'Email',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Date Created',
                value: 'createdDate',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              },
              {
                title: 'Time Created',
                value: 'createdTime',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'f5d5b9' } }
                }
              }
            ],
            valueKeys: ['email', 'createdDate', 'createdTime'],
            data: data,
            sheetName: 'Form Submit'
          }
        ]}
      />
    </Styled>
  );
}

export default Index;
