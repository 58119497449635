import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import CustomTable from '../../../components/CustomTable';
import CustomButton from '../../../components/CustomButton';
import { sendGet } from '../../../utils/requestHandle';
import { sortArrayWithKey } from '../../../utils/global';

const { EditButton, DeleteButton } = CustomButton;

const rootPath = 'career';
const path = 'career/privilege';
const outerDataKey = 'privilege';
const dataKey = 'privilege.privileges';

function Index() {
  const action = 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [description, setDescription] = useState();
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState();
  useEffect(() => {
    async function fetchDoc(url) {
      const result = await sendGet(url);
      set_id(_.get(result, 'data.data._id'));
      const outerData =
        outerDataKey !== ''
          ? _.get(result, `data.data.${outerDataKey}`, [])
          : '';
      setBackgroundImage(_.get(outerData, 'backgroundImage'));
      setDescription(_.get(outerData, 'description'));
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      sortArrayWithKey(data, 'order');
      setFullData(data);
      setData(data);
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
  }, []);
  const columns = [
    { title: 'Title', dataIndex: 'title.en' },
    { title: 'Status', dataIndex: 'status' },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text, record) => (
        <div className='row-operation-container'>
          <EditButton path={`/${path}/edit/${_.get(record, '_id')}`} />
          <DeleteButton
            url={`${process.env.REACT_APP_API_HOST}/${path}/delete`}
            _id={_id}
            data={data}
            setData={setData}
            childId={_.get(record, '_id')}
            dataKey={dataKey}
          />
        </div>
      )
    }
  ];
  return (
    <Styled>
      <CustomTable
        autoHeight={true}
        title='Privilege List'
        searchKeys={['_id', 'title.en', 'status']}
        rootId={_id}
        childKey={dataKey}
        fullData={fullData}
        path={path}
        data={data}
        setData={setData}
        columns={columns}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        actionComponents={[
          { type: 'new', title: 'Create', path: `/${path}/new` }
        ]}
      />
      <CustomForm
        autoHeight={true}
        action={action}
        title={`${action} Privilege Section`}
        inputFields={[
          {
            key: 'backgroundImage',
            state: backgroundImage,
            setState: setBackgroundImage,
            label: 'Background Image',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '1920x700',
            previewWidth: '400px'
          },
          {
            key: 'description',
            state: description,
            setState: setDescription,
            multiLang: true,
            label: 'Description',
            type: 'textarea'
          }
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: outerDataKey,
            updateOuter: true
          }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default Index;
