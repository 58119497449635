import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import {
  statusOptions,
  resourceTypeOptions,
  overlayPositionOptions
} from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import { fetchNextOrderOfSubCollection, getNext } from '../../../utils/global';

const rootPath = 'home';
const dataKey = 'banner.banners';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const [_id, set_id] = useState();
  const childId = _.get(props, 'match.params._id');
  const path = `home/banner`;
  const [isLoading, setIsLoading] = useState(false);
  const [resourceName, setResourceName] = useState();
  const [resourceType, setResourceType] = useState('Image');
  const [pcResource, setPcResource] = useState();
  const [mobileResource, setMobileResource] = useState();
  const [linkIn, setLinkIn] = useState();
  const [linkOut, setLinkOut] = useState();
  const [logo, setLogo] = useState();
  const [pcOverlayImage, setPcOverlayImage] = useState();
  const [mobileOverlayImage, setMobileOverlayImage] = useState();
  const [overlayTextColor, setOverlayTextColor] = useState();
  const [overlayBackgroundColor, setOverlayBackgroundColor] = useState();
  const [overlayPosition, setOverlayPosition] = useState('Left');
  const [overlayStatus, setOverlayStatus] = useState('Show');
  const [buttonTextColor, setButtonTextColor] = useState();
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState();
  const [buttonStatus, setButtonStatus] = useState('Show');
  const [description1, setDescription1] = useState();
  const [description2, setDescription2] = useState();
  const [description3, setDescription3] = useState();
  const [description4, setDescription4] = useState();
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  const [forceUpdate, setForceUpdate] = useState();
  const inputFields = [
    {
      key: 'resourceName',
      state: resourceName,
      setState: setResourceName,
      label: 'Resource Name',
      type: 'text'
    },
    {
      key: 'resourceType',
      state: resourceType,
      setState: setResourceType,
      label: 'Resource Type',
      type: 'select',
      valuesForSelect: resourceTypeOptions
    },
    {
      key: 'pcResource',
      state: pcResource,
      setState: setPcResource,
      label: 'Pc Resource',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'file',
      maxFileSize: 50,
      resolution: '1920x1080',
      previewWidth: '400px'
    },
    {
      key: 'mobileResource',
      state: mobileResource,
      setState: setMobileResource,
      label: 'Mobile Resource',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'file',
      maxFileSize: 50,
      resolution:
        '1920x1080 กรณีมีกรอบ Box ข้อความ / 1024x1600 กรณีไม่มี Box ข้อความ',
      previewWidth: '370px'
    },
    {
      key: 'linkIn',
      state: linkIn,
      setState: setLinkIn,
      label: 'Link In',
      type: 'text'
    },
    {
      key: 'linkOut',
      state: linkOut,
      setState: setLinkOut,
      label: 'Link Out',
      type: 'text'
    },
    {
      key: 'logo',
      state: logo,
      setState: setLogo,
      label: 'Logo',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'image',
      maxFileSize: 1,
      resolution: '200x100',
      previewWidth: '200px'
    },
    {
      key: 'pcOverlayImage',
      state: pcOverlayImage,
      setState: setPcOverlayImage,
      label: 'Pc Overlay Image',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'image',
      maxFileSize: 5,
      resolution: '770x610',
      previewWidth: '400px'
    },
    {
      key: 'mobileOverlayImage',
      state: mobileOverlayImage,
      setState: setMobileOverlayImage,
      label: 'Mobile Overlay Image',
      type: 'file',
      setForceUpdate: setForceUpdate,
      previewType: 'image',
      maxFileSize: 3,
      resolution: '160x190',
      previewWidth: '160px'
    },
    {
      key: 'overlayTextColor',
      state: overlayTextColor,
      setState: setOverlayTextColor,
      label: 'Overlay Text Color',
      type: 'colorPicker'
    },
    {
      key: 'overlayBackgroundColor',
      state: overlayBackgroundColor,
      setState: setOverlayBackgroundColor,
      label: 'Overlay Background Color',
      type: 'colorPicker'
    },
    {
      key: 'overlayPosition',
      state: overlayPosition,
      setState: setOverlayPosition,
      label: 'Overlay Position',
      type: 'select',
      valuesForSelect: overlayPositionOptions
    },
    {
      key: 'overlayStatus',
      state: overlayStatus,
      setState: setOverlayStatus,
      label: 'Overlay Status',
      type: 'select',
      valuesForSelect: statusOptions
    },
    {
      key: 'buttonTextColor',
      state: buttonTextColor,
      setState: setButtonTextColor,
      label: 'Button Text Color',
      type: 'colorPicker'
    },
    {
      key: 'buttonBackgroundColor',
      state: buttonBackgroundColor,
      setState: setButtonBackgroundColor,
      label: 'Button Background Color',
      type: 'colorPicker'
    },
    {
      key: 'buttonStatus',
      state: buttonStatus,
      setState: setButtonStatus,
      label: 'Button Status',
      type: 'select',
      valuesForSelect: statusOptions
    },
    {
      key: 'description1',
      state: description1,
      setState: setDescription1,
      label: 'Description 1',
      multiLang: true,
      type: 'textarea'
    },
    {
      key: 'description2',
      state: description2,
      setState: setDescription2,
      label: 'Description 2',
      multiLang: true,
      type: 'textarea'
    },
    {
      key: 'description3',
      state: description3,
      setState: setDescription3,
      label: 'Description 3',
      multiLang: true,
      type: 'textarea'
    },
    {
      key: 'description4',
      state: description4,
      setState: setDescription4,
      label: 'Description 4',
      multiLang: true,
      type: 'textarea'
    },
    {
      key: 'status',
      state: status,
      setState: setStatus,
      label: 'Status',
      type: 'select',
      valuesForSelect: statusOptions
    },
    {
      key: 'order',
      state: order,
      setState: setOrder,
      label: 'Order',
      type: 'number'
    }
  ];
  useEffect(() => {
    setIsLoading(true);
    async function fetchDoc(url) {
      const result = await sendGet(url);
      const rootId = _.get(result, 'data.data._id');
      set_id(rootId);
      // fetchNextOrderOfSubCollection(
      //   `${process.env.REACT_APP_API_HOST}/${path}/max/order?_id=${rootId}&dataKey=${dataKey}`,
      //   setOrder,
      //   setIsLoading
      // );
      const data =
        dataKey !== ''
          ? _.get(result, `data.data.${dataKey}`, [])
          : _.get(result, 'data.data', []);
      const nextOrder = getNext('order', data);
      const childData = _.find(data, d => _.get(d, '_id') === childId);
      setResourceName(_.get(childData, 'resourceName'));
      setResourceType(_.get(childData, 'resourceType', 'Image'));
      setPcResource(_.get(childData, 'pcResource'));
      setMobileResource(_.get(childData, 'mobileResource'));
      setLinkIn(_.get(childData, 'linkIn'));
      setLinkOut(_.get(childData, 'linkOut'));
      setLogo(_.get(childData, 'logo'));
      setPcOverlayImage(_.get(childData, 'pcOverlayImage'));
      setMobileOverlayImage(_.get(childData, 'mobileOverlayImage'));
      setOverlayTextColor(_.get(childData, 'overlayTextColor'));
      setOverlayBackgroundColor(_.get(childData, 'overlayBackgroundColor'));
      setOverlayPosition(_.get(childData, 'overlayPosition', 'Left'));
      setOverlayStatus(_.get(childData, 'overlayStatus', 'Show'));
      setButtonTextColor(_.get(childData, 'buttonTextColor'));
      setButtonBackgroundColor(_.get(childData, 'buttonBackgroundColor'));
      setButtonStatus(_.get(childData, 'buttonStatus', 'Show'));
      setDescription1(_.get(childData, 'description1'));
      setDescription2(_.get(childData, 'description2'));
      setDescription3(_.get(childData, 'description3'));
      setDescription4(_.get(childData, 'description4'));
      setStatus(_.get(childData, 'status', 'Show'));
      setOrder(_.get(childData, 'order', nextOrder));
      setIsLoading(false);
    }
    fetchDoc(`${process.env.REACT_APP_API_HOST}/${rootPath}/first-doc`);
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} Banner`}
        inputFields={inputFields}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading,
            redirectPath: `/${path}`,
            dataKey: dataKey,
            childId: childId
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
