import styled from 'styled-components';

export default styled.div`
  .new-button {
    float: right;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #957d53;
    border-color: #957d53;
    &:hover {
      background-color: #aa9775;
      border-color: #957d53;
    }
    &:active, &:focus {
      background-color: #957d53;
      border-color: #957d53;
    }
  }
  .edit-button {
    margin-right: 15px;
    color: orange;
  }
  .delete-button {
    margin-right: 15px;
    color: red;
  }
  .submit-button {
    background-color: #957d53;
    border-color: #957d53;
    &:hover {
      background-color: #aa9775;
      border-color: #957d53;
    }
    &:active, &:focus {
      background-color: #957d53;
      border-color: #957d53;
    }
  }
  .back-button {
    margin-right: 20px;
    background-color: #727575;
    border-color: #727575;
    &:hover {
      background-color: #8e9191;
      border-color: #727575;
    }
    &:active, &:focus {
      background-color: #727575;
      border-color: #727575;
    }
  }
  .download-excel-button {
    float: right;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #957d53;
    border-color: #957d53;
    &:hover {
      background-color: #aa9775;
      border-color: #957d53;
    }
    &:active, &:focus {
      background-color: #957d53;
      border-color: #957d53;
    }
  }
`;