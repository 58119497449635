import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import Styled from './styled';
import CustomForm from '../../../components/CustomForm';
import { statusOptions, currentDate } from '../../../utils/variables';
import { sendGet } from '../../../utils/requestHandle';
import { fetchNextOrderOfCollection } from '../../../utils/global';

const path = 'news/article';

function Form(props) {
  const lastUrlSegment = window.location.pathname.split('/').pop();
  const action = lastUrlSegment === 'new' ? 'New' : 'Edit';
  const actionPath = action === 'New' ? 'create' : 'update';
  const _id = _.get(props, 'match.params._id');
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [fullDescription, setFullDescription] = useState();
  const [thumbnail, setThumbnail] = useState();
  // const [bannerImage, setBannerImage] = useState();
  const [gallery, setGallery] = useState();
  const [isHighlight, setIsHighlight] = useState(false);
  // const [tf, setTf] = useState();
  const [publishedDate, setPublishedDate] = useState(currentDate);
  const [status, setStatus] = useState('Show');
  const [order, setOrder] = useState(1);
  // const [dynamic, setDynamic] = useState({en:[{title:'test1'},{title:'test2'}], th:[{title:'ไตเติ้ล3'},{title:'หัวข้อ4'}] });
  // const [dynamic, setDynamic] = useState([{title:'test1'},{title:'test2'},{title:'ไตเติ้ล3'},{title:'หัวข้อ4'}]);
  const [dynamic, setDynamic] = useState();
  const [forceUpdate, setForceUpdate] = useState();
  // const [color, setColor] = useState();
  // const [transfer, setTransfer] = useState();
  // const [valuesForTransferDataSource, setValuesForTransferDataSource] = useState([{_id: 'key1', label: 'label1'},{_id: 'key2', label:'label2'}]);
  useEffect(() => {
    setIsLoading(true);
    if (action === 'Edit') {
      async function fetchDoc(url) {
        const result = await sendGet(url);
        const data = _.get(result, 'data.data');
        setTitle(_.get(data, 'title'));
        setShortDescription(_.get(data, 'shortDescription'));
        setFullDescription(_.get(data, 'fullDescription'));
        setThumbnail(_.get(data, 'thumbnail'));
        // setBannerImage(_.get(data, 'bannerImage'));
        setGallery(_.get(data, 'gallery'));
        setIsHighlight(_.get(data, 'isHighlight'));
        // setTf(_.get(data, 'tf'));
        setPublishedDate(_.get(data, 'publishedDate', currentDate));
        setStatus(_.get(data, 'status', 'Show'));
        setOrder(_.get(data, 'order', 1));
        setDynamic(_.get(data, 'dynamic'));
        // setColor(_.get(data, 'color'));
        // setTransfer(_.get(data, 'transfer'));
        setIsLoading(false);
      }
      fetchDoc(`${process.env.REACT_APP_API_HOST}/${path}/view/${_id}`);
    } else {
      fetchNextOrderOfCollection(
        `${process.env.REACT_APP_API_HOST}/${path}/max/order`,
        setOrder,
        setIsLoading
      );
    }
  }, []);
  return (
    <Styled>
      <CustomForm
        action={action}
        title={`${action} News`}
        inputFields={[
          {
            key: 'title',
            state: title,
            setState: setTitle,
            multiLang: true,
            label: 'Title',
            type: 'text',
            validation: [{ type: 'required' }]
          },
          {
            key: 'shortDescription',
            state: shortDescription,
            setState: setShortDescription,
            multiLang: true,
            label: 'Short Description',
            type: 'textarea'
          },
          {
            key: 'fullDescription',
            state: fullDescription,
            setState: setFullDescription,
            multiLang: true,
            label: 'Full Description',
            placeholder: 'Enter Full Description',
            type: 'editor',
            action: action,
            height: '500px'
          },
          {
            key: 'thumbnail',
            state: thumbnail,
            setState: setThumbnail,
            label: 'Thumbnail',
            type: 'file',
            setForceUpdate: setForceUpdate,
            previewType: 'image',
            maxFileSize: 5,
            resolution: '900x600',
            previewWidth: '300px'
          },
          // {
          //   key: 'bannerImage',
          //   state: bannerImage,
          //   setState: setBannerImage,
          //   label: 'Banner Image',
          //   type: 'file',
          //   previewType: 'image',
          //   maxFileSize: 5,
          //   resolution: '1200x720',
          //   previewWidth: '400px'
          // },
          {
            key: 'gallery',
            state: gallery,
            setState: setGallery,
            label: 'Gallery',
            type: 'file',
            setForceUpdate: setForceUpdate,
            isMultiple: true,
            previewType: 'image-list',
            resolution: '850x500'
          },
          // { key: 'tf', state: tf, setState: setTf, label: 'Tf', type: 'file', isMultiple: true, previewType: 'file-list', maxFileSize: 300 },
          {
            key: 'publishedDate',
            state: publishedDate,
            setState: setPublishedDate,
            label: 'Published Date',
            type: 'date'
          },
          {
            key: 'isHighlight',
            state: isHighlight,
            setState: setIsHighlight,
            label: 'Pin highlight',
            type: 'check'
          },
          {
            key: 'status',
            state: status,
            setState: setStatus,
            label: 'Status',
            type: 'select',
            valuesForSelect: statusOptions
          },
          {
            key: 'order',
            state: order,
            setState: setOrder,
            label: 'Order',
            type: 'number'
          }
          // { key: 'dynamic', state: dynamic, setState: setDynamic, label: 'Dynamic', multiLang: true, type: 'dynamic', setForceUpdate: setForceUpdate },
          // { key: 'color', state: color, setState: setColor, label: 'color', type: 'colorPicker' },
          // { key: 'transfer', state: transfer, setState: setTransfer, label: 'Transfer', type: 'transfer', renderKey: 'label', valueKey: '_id', valuesForDataSource: valuesForTransferDataSource },
        ]}
        actionComponents={[
          {
            type: 'submit',
            title: 'Submit',
            url: `${process.env.REACT_APP_API_HOST}/${path}/${actionPath}`,
            path: path,
            _id: _id,
            setIsLoading: setIsLoading
          },
          { type: 'back', title: 'Back', path: `/${path}` }
        ]}
        isLoading={isLoading}
      />
    </Styled>
  );
}

export default withRouter(Form);
