import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.autoHeight ? 'auto': '100vh'};
  padding: 20px;
  overflow-y: auto;
  background-color: white;
  .ant-tabs-bar {
    margin: 10px 0 20px 0;
  }
  .ant-card-type-inner .ant-card-head-title {
    font-size: 21px;
  }
  .button-container {
    display: flex;
    margin-top: 20px;
  }
  .ant-tabs {
    border: 1px solid #e8e8e8;
    background-color: white;
    padding: 5px 15px 0 15px;
  }
  .inline-container {
    display: flex;
    flex-wrap: wrap;
  }
`;