import styled from 'styled-components';

export default styled.div`
  .input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px 0;
    .input-label {
      font-size: 16px;
      margin-bottom: 3px;
      color: #5d5d5d;
      font-weight: 600;
    }
    .ant-checkbox + span {
      font-size: 16px;
      padding-left: 10px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
    .ant-input {
      padding: 2px;
    }
    .file-condition-text {
      font-size: 12px;
      font-weight: 500;
    }
    .delete-icon {
      text-align: left;
      margin-top: 5px;
      cursor: pointer;
      svg {
        color: red;
        width: 14px;
        height: 14px;
      }
    }
    div[id^='preview-file-list-container'] {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    div[id^='preview-image-list-container'] {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    div[id^='preview-file-list-single-container'] {
      display: flex;
      margin: 0 0 0 0;
      width: 100%;
    }
    div[id^='preview-image-list-single-container'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 5px 5px 0;
      width: calc(25% - 5px);
      height: 260px;
      background: rgb(221, 210, 202);
    }
    a[id^='preview-file-list'] {
      margin-right: 5px;
      color: #595959;
      font-weight: 500;
    }
    img[id^='preview-image-list'] {
      display: flex;
      max-width: 100%;
      max-height: 230px;
      object-fit: contain;
    }
    a[id^='preview-file'] {
      margin-right: 5px;
      color: #595959;
      font-weight: 500;
    }
    img[id^='preview-image'] {
      cursor: zoom-in;
    }
    .dynamic-input-container {
      display: flex;
      flex-direction: column;
      padding: 15px;
      border: 1px solid #e8e8e8;
      background-color: #e8e8e8;
    }
    div[class^='dynamic-input-single-container'] {
      position: relative;
      display: flex;
      padding: 15px;
      margin-bottom: 15px;
      border: 1px solid #e8e8e8;
      background-color: white;
    }
    .dynamic-input-left {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }
    .dynamic-input-right {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .dynamic-input-right-inline {
      display: flex;
      width: 100%;
      .title-inline {
        width: 28%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .description-inline {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
    .plus-square-icon {
      margin-top: 10px;
      cursor: pointer;
      svg {
        width: 30px;
        height: 30px;
        color: #727575;
      }
    }
    .dynamic-single-delete-icon {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
      svg {
        color: red;
        width: 15px;
        height: 15px;
      }
    }
  }
  .switch-container {
    width: 180px;
  }
  .ant-radio-button-wrapper {
    margin-top: 2px;
  }
`;


